import Button from "../../molecules/buttons/Button";
import style from "./List.module.css";

function List(props) {


  return (
    <div>
      <table cellPadding="0" cellSpacing="0" className={style.list}>
        <thead>
          <tr>
            {props.columnHeaders.map((column, index) => (
              <th key={index} className={style[column.style]}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((row, index) => (
            <tr key={index} onClick={() => props.onItemClick(props.squad+'/'+row.key)}>
              <td className={style.name}>{row.name}</td>
              <td className={row.meta1.adjective} style={row.meta1.style}>{row.meta1.value}</td>
              <td><Button name="view" /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default List;
