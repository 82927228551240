import { useNavigate } from "react-router-dom";
import style from "./Quarter.module.css";

const Quarter = (props) => {
  const navigate = useNavigate();

  function clickHandler(id) {
    navigate(id);
  }

  return (
    <div className={style.quarter} onClick={() => clickHandler(props.title)}>
      <span className={style.title}>{props.title}</span>
      <div>
        <div>
          <span>4</span>
          Initiatives
        </div>
        <div>
          <span>2</span>
          Ready
        </div>
      </div>
    </div>
  );
};

export default Quarter;
