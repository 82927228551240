import { useState, useEffect, useRef } from "react";

import style from "./TextField.module.css";

function TextField(props) {
  const inpRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(props.error);
    if(props.onParentChange) {
      props.onParentChange(props.value);
    }
  }, [props]);

  function focusHandler(event) {
    event.currentTarget.querySelector("input").focus();
  }

  return (
    <div
      className={`${style.textField} ${error && style.error}`}
      onClick={focusHandler}
    >
      <input
        ref={inpRef}
        spellCheck="false"
        name={props.name}
        type={props.textFieldType}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      <label>{props.label}</label>
    </div>
  );
}

export default TextField;
