import style from "./Fab.module.css";

function Fab(props) {
  return (
    <button
      className={`${style.fab} ${style[props.inContainer]} ${props.name && style.hasText}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <span className={`${"material-symbols-rounded"} ${style.icon}`}>
          {props.icon}
        </span>
      ) : (
        false
      )}
      {props.name ? <span className={style.text}>{props.name}</span> : ""}
    </button>
  );
}

export default Fab;
