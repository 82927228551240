import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import UserContext from "../../user/User";

import Card from "../../organisms/cards/Card";
import style from "./Home.module.css";

function Home() {
  const user = useContext(UserContext);
  const [showPlanning, setShowPlanning] = useState(false);
  const [showDiscovery, setShowDiscovery] = useState(false);
  const [showJourneys, setShowJourneys] = useState(false);
  const [showAreaMapping, setAreaMapping] = useState(false);

  useEffect(() => {
    if (user.id) {
      if (user.products.includes("planning")) {
        setShowPlanning(true);
      }
      if (user.products.includes("journeys")) {
        setShowJourneys(true);
      }
      if (user.products.includes("areaMapping")) {
        setAreaMapping(true);
      }
      if (user.products.includes("discovery")) {
        setShowDiscovery(true);
      }
    }
  }, [user]);

  const ConditionalLink = ({ children, to, condition }) =>
    condition && to ? <Link to={to}>{children}</Link> : <>{children}</>;

  return (
    <div className={style.home}>
      {showPlanning ? (
        <>
        <ConditionalLink to="/planning" condition={user.id}>
          <Card
            title="Planning"
            description="Plan and prioritise your initiatives"
            image={require("./planning.png")}
          />
        </ConditionalLink>
        </>
      ) : (
        false
      )}
      {showDiscovery ? (
        <>
        <ConditionalLink to="/discovery" condition={user.id}>
          <Card
            title="Discovery"
            description="Plan and prioritise your initiatives"
            image={require("./planning.png")}
          />
        </ConditionalLink>
        </>
      ) : (
        false
      )}
      {showJourneys ? (
        <ConditionalLink to="/journeys" condition={user.id}>
          <Card
            title="Journeys"
            description="Draw out your key flows and document your analysis"
            image={require("./journeys.png")}
          />
        </ConditionalLink>
      ) : (
        false
      )}
      {showAreaMapping ? (
        <ConditionalLink to="/area-mapping" condition={user.id}>
          <Card
            title="Area Mapping"
            description="Determin how the real estate on your user interface is being used"
            image={require("./areaMaps.png")}
          />
        </ConditionalLink>
      ) : (
        false
      )}
    </div>
  );
}

export default Home;
