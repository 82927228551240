import style from './Member.module.css';

function Member(props) {
  return <div className={style.member}>
    <div className={style.image}><img src={require('../images/'+props.image)} /></div>
    <div className={style.name}>{props.name}</div>
    <div className={style.jobTitle}>{props.jobTitle}</div>
    <div className={style.squad}>{props.squad}</div>
  </div>
}

export default Member;