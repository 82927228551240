import { useState } from 'react';
import style from './Form.module.css'

function Form(props) {
    // const [error, setError] = useState(false);
    let error = false;
    if(props.error){
        error = <div className={style.error}>{props.error}</div>;
    }
    return <form onSubmit={props.onSubmit}>
        {error}
        {props.children}
    </form>
}

export default Form;