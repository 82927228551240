import style from './Slide.module.css'


function Slide(props) {
  return (
    <label className={style.slideContainer} id={props.id}>
      <input type="radio" name="selectedSlide" value={props.id} />
      <div className={`${style.slide} ${style[props.variant]}`}>
        {props.title ? <h2 className={style.h2}>{props.title}</h2> : false}
        <div className={`${style.content} ${style[props.contentVariant]}`}>
          {props.children}
        </div>
      </div>
    </label>
  );
}


export default Slide;