import { useState } from "react";
import ContentEditable from "react-contenteditable";
import style from "./Time.module.css";

function Time(props) {
  const [checked, setChecked] = useState(props.data.state);

  function changeHandler(e) {
    if (isNaN(e.target.value)) {
      return false;
    }
    if (!e.target.value) {
      e.target.value = 0;
    }
    if (parseInt(e.target.value) > 100) {
      e.target.value = 100;
    }
    if (parseInt(e.target.value) < 0) {
      e.target.value = 0;
    }

    props.onChange(e);
  }

  function changeHandler2(e) {
    if (isNaN(e.target.value)) {
      return false;
    }
    if (!e.target.value) {
      e.target.value = 0;
    }
    if (parseInt(e.target.value) > 100) {
      e.target.value = 100;
    }
    if (parseInt(e.target.value) < 0) {
      e.target.value = 0;
    }

    props.onChange2(e);
  }

  function pressed(e) {
    console.log(e.keyCode);
    if (
      (e.keyCode < 48 || e.keyCode > 57) &&
      e.keyCode !== 8 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 9
    ) {
      e.preventDefault();
      return false;
    }
  }

  function disable(e) {
    setChecked(!checked);
    props.changeState(e);
  }

  return (
    <div className={style.time}>
      {props.disabled ? (
        false
      ) : (
        <input type="checkbox" checked={checked} onChange={(e) => disable(e)} />
      )}
      {checked ?
      <>
      <span className={`${"material-symbols-rounded"} ${style.icon}`}>
        schedule
      </span>
      <div>
        <ContentEditable
          html={"" + props.data.content}
          onChange={(e) => changeHandler(e)}
          tagName="span"
          onKeyDown={(e) => pressed(e)}
          disabled={props.disabled}
        />
        m
      </div>
      <div>
        <ContentEditable
          html={"" + props.data.content2}
          onChange={(e) => changeHandler2(e)}
          tagName="span"
          onKeyDown={(e) => pressed(e)}
          disabled={props.disabled}
        />
        s
      </div>
      </>
      : false}
    </div>
  );
}

export default Time;
