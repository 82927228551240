import { useEffect, useRef } from 'react';
import style from './ContextMenu.module.css'

function ContextMenu(props) {
  const ref = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  function handleClickOutside(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      props.closeContextMenu();
    }
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }

  function handleClick(action) {
    // props.closeContextMenu();
    props.contextMenuAction(action, props.item)
  }

  return <ul ref={ref} className={`${style.contextMenu} ${props.show && style.show}`} style={props.style} onContextMenu={handleContextMenu}>
    {
      {
        lifeCycle: <><li onClick={() => handleClick('subType:Consideration')}>Consideration</li>
        <li onClick={() => handleClick('subType:Sign-Up')}>Sign-Up</li>
        <li onClick={() => handleClick('subType:Activation')}>Activation</li>
        <li onClick={() => handleClick('subType:Adoption')}>Adoption</li>
        <li onClick={() => handleClick('subType:In-Life')}>In-Life</li>
        <li onClick={() => handleClick('subType:Reactivation')}>Reactivation</li>
        <li>---------------</li></>
      }[props.type]
    }
  <li onClick={() => handleClick('cellRight')}>Add cell right</li>
  <li onClick={() => handleClick('cellLeft')}>Add cell left</li>
  <li onClick={() => handleClick('cellDelete')}>Delete cell</li>
  <li onClick={() => handleClick('columnRight')}>Add column right</li>
  <li onClick={() => handleClick('columnLeft')}>Add column left</li>
  <li onClick={() => handleClick('columnDelete')}>Delete column</li>
</ul>
}

export default ContextMenu;