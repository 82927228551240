import { useEffect, useState } from "react";
import style from "./RadioTabs.module.css";

function RadioTabs(props) {
  const [tabs, setTabs] = useState([]);
  const [defaultVal, setDefaultVal] = useState(0);

  useEffect(() => {
    setTabs(props.tabs);
    setDefaultVal(props.selected);
  }, [props]);

  function changeHandler(e) {
    props.onChange(e.target.value);
  }

  return (
    <div className={style.radioTabs} >
      {tabs.map((tab) => (
        <div key={tab.key}>
          <input
            type="radio"
            defaultValue={tab.value}
            id={props.tabGroup + "-" + tab.key}
            name={props.tabGroup}
            checked={defaultVal == tab.value ? true : false}
            onChange={changeHandler}
          />
          <label htmlFor={props.tabGroup + "-" + tab.key}>{tab.name}</label>
        </div>
      ))}
    </div>
  );
}

export default RadioTabs;
