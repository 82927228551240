import { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import style from "./ConversionCell.module.css";

function ConversionCell(props) {
  const [topLeft, setTopLeft] = useState(0);
  const [topRight, setTopRight] = useState(0);
  const [bottomRight, setBottomRight] = useState(0);
  const [bottomLeft, setBottomLeft] = useState(0);
  const [colorFrom, setColorFrom] = useState("#fff");
  const [colorTo, setColorTo] = useState("#fff");
  const [itemID, setItemID] = useState("0000");

  function getColor(value) {
    //value from 0 to 1
    // console.log('color', value);
    var hue = (value * 120).toString(10);
    return ["hsl(", hue, ",65%,70%)"].join("");
  }

  useEffect(() => {
    setTopLeft(0.8 * ((100 - parseFloat(props.data.content)) / 2));
    setBottomLeft(0.8 * (100 - (100 - parseFloat(props.data.content)) / 2));
    setColorFrom(parseFloat(props.data.content));
    if (props.nextData) {
      setTopRight(0.8 * ((100 - parseFloat(props.nextData.content)) / 2));
      setBottomRight(
        0.8 * (100 - (100 - parseFloat(props.nextData.content)) / 2)
      );
      setColorTo(parseFloat(props.nextData.content));
      setItemID(props.data.content + "-" + props.nextData.content);
    } else {
      setTopRight((0.8 * (100 - parseFloat(props.data.content))) / 2);
      setBottomRight(0.8 * (100 - (100 - parseFloat(props.data.content)) / 2));
      setColorTo(parseFloat(props.data.content));
      setItemID(props.data.content + "-" + props.data.content);
    }
  }, [props.data.content, props.time]);

  function changeHandler(e) {
    let tempVal = e.target.value;

    if (isNaN(e.target.value)) {
      return false;
    }
    if(!e.target.value) {
      tempVal = 0;
    }
    
    if(parseFloat(e.target.value) > 100) {
      tempVal = 100;
    }
    if(parseFloat(e.target.value) < 0) {
      tempVal = 0;
    }
    e.target.value = parseFloat(tempVal).toFixed(2);
    
    props.onChange(e);
  }

  function pressed(e) {
    // console.log(e.keyCode);
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39 && e.keyCode !== 9 && e.keyCode !== 190) {
      e.preventDefault();
      return false;
    }
  }



  return (
    <div className={style.cell}>
      <div className={style.num}>
      <ContentEditable
        html={""+props.data.content}
        onChange={(e) => changeHandler(e)}
        tagName='span'
        onKeyDown={(e) => pressed(e)}
        disabled={props.disabled}
      />%</div>
      <svg width="252" height="80">
        <path
          fill={"url(#Gradient" + itemID + ")"}
          d={
            "M 0 " +
            topLeft +
            " C 100 " +
            topLeft +
            ", 100 " +
            topRight +
            ", 252 " +
            topRight +
            " L 252 " +
            bottomRight +
            " C 100 " +
            bottomRight +
            ", 100 " +
            bottomLeft +
            ", 0 " +
            bottomLeft +
            ""
          }
        />
        <defs>
          <linearGradient id={"Gradient" + itemID}>
            <stop
              stopColor={getColor(colorFrom / 100)}
              stopOpacity="100%"
              offset="0%"
            />
            <stop
              stopColor={getColor(colorTo / 100)}
              stopOpacity="100%"
              offset="100%"
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default ConversionCell;
