import { useRef, useState, useEffect } from "react";
import { ref, onValue, update } from "firebase/database";
import db from "../../../firebase/Firebase";

import Member from "./components/Member";
import Slide from "./components/Slide";
import style from "./Presentation.module.css";

function Presentation() {
  const refSlides = useRef();
  const participantRef = ref(db, "experiments/ikeaEffect/participants");
  const slideNumberRef = ref(db, "experiments/slideNumber");
  const [numberOfParticipants, setNumberOfParticipants] = useState(0);
  const [numberOfItem1Responses, setNumberOfItem1Responses] = useState(0);
  const [numberOfItem2Responses, setNumberOfItem2Responses] = useState(0);
  const [averageOfItem1, setAverageOfItem1] = useState(0);
  const [users, setUsers] = useState([]);
  const [averageOfItem2, setAverageOfItem2] = useState(0);
  const [averageOfItem2Actual, setAverageOfItem2Actual] = useState(0);
  const [currentSlide, _setCurrentSlide] = useState(1);
  const currentSlideRef = useRef(currentSlide);
  const [surpressed, setSurpressed] = useState(true);
  const setCurrentSlide = (data) => {
    currentSlideRef.current = data;
    _setCurrentSlide(data);
  };
  const [totalSlides, _setTotalSlides] = useState(0);
  const totalSlidesRef = useRef(totalSlides);
  const setTotalSlides = (data) => {
    totalSlidesRef.current = data;
    _setTotalSlides(data);
  };

  const [testATimeTaken, setTestATimeTaken] = useState(0);
  const [testAPerceivedTime, setTestAPerceivedTime] = useState(0);
  const [testAEOU, setTestAEOU] = useState(0);
  const [testASUS, setTestASUS] = useState(0);
  const [SUSColorA, setSUSColorA] = useState('#00c2ff');

  const [testBTimeTaken, setTestBTimeTaken] = useState(0);
  const [testBPerceivedTime, setTestBPerceivedTime] = useState(0);
  const [testBEOU, setTestBEOU] = useState(0);
  const [testBSUS, setTestBSUS] = useState(0);
  const [SUSColorB, setSUSColorB] = useState('#00c2ff');

  function openFullscreen(elem) {
    elem = elem.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  useEffect(() => {
    onValue(participantRef, (snapshot) => {
      let usersArr = [];
      let total = 0;
      let totalTest1 = 0;
      let totalTest2 = 0;
      let totalMoney1 = 0;
      let totalMoney2 = 0;
      let data = snapshot.val();

      let temp_testAParticipants = 0;
      let temp_testATimeTaken = 0;

      let temp_testAParticipantsPerceivedTime = 0;
      let temp_testAPerceivedTime = 0;

      let temp_testAParticipantsEOU = 0;
      let temp_testAEOU = 0;

      let temp_testAParticipantsSUS = 0;
      let temp_testASUS = 0;


      let temp_testBParticipants = 0;
      let temp_testBTimeTaken = 0;

      let temp_testBParticipantsPerceivedTime = 0;
      let temp_testBPerceivedTime = 0;

      let temp_testBParticipantsEOU = 0;
      let temp_testBEOU = 0;

      let temp_testBParticipantsSUS = 0;
      let temp_testBSUS = 0;

      for (const item of Object.keys(data)) {
        total += 1;
        usersArr.push(data[item]);
        if (data[item].test1) {
          totalTest1 += 1;
          totalMoney1 += data[item].test1;
        }

        if (data[item].test2) {
          totalTest2 += 1;
          totalMoney2 += data[item].test2;
        }
        if (data[item].testVersion === "reg-a") {
          if (data[item].elapsedTime !== undefined) {
            temp_testATimeTaken += parseFloat(data[item].elapsedTime);
            temp_testAParticipants += 1;
          }

          if (data[item].perceivedTimeSecs !== undefined) {
            let secs = 0;
            secs = parseInt(data[item].perceivedTimeSecs);
            temp_testAPerceivedTime += parseFloat(secs);
            temp_testAParticipantsPerceivedTime += 1;
          }

          if (data[item].q1 !== undefined) {
            temp_testAEOU += parseInt(data[item].q1);
            temp_testAParticipantsEOU += 1;
          }

          if (
            data[item].sus1 !== undefined &&
            data[item].sus2 !== undefined &&
            data[item].sus3 !== undefined &&
            data[item].sus4 !== undefined &&
            data[item].sus5 !== undefined &&
            data[item].sus6 !== undefined &&
            data[item].sus7 !== undefined &&
            data[item].sus8 !== undefined &&
            data[item].sus9 !== undefined &&
            data[item].sus10 !== undefined
          ) {
            let x = (parseInt(data[item].sus1) + parseInt(data[item].sus3) + parseInt(data[item].sus5) + parseInt(data[item].sus7) + parseInt(data[item].sus9)) - 5;
            let y = 25 - (parseInt(data[item].sus2) + parseInt(data[item].sus4) + parseInt(data[item].sus6) + parseInt(data[item].sus8) + parseInt(data[item].sus10));
            let sus = (x + y) * 2.5;
            // console.log("========");
            // console.log(sus.toFixed(0));
            // console.log(temp_testASUS);
            temp_testASUS += parseInt(sus.toFixed(0));
            temp_testAParticipantsSUS += 1;
          }
        }
        if (data[item].testVersion === "reg-b") {
          if (data[item].elapsedTime !== undefined) {
            temp_testBTimeTaken += parseFloat(data[item].elapsedTime);
            temp_testBParticipants += 1;
          }

          if (data[item].perceivedTimeSecs !== undefined) {
            let secs = 0;
            secs = parseInt(data[item].perceivedTimeSecs);
            temp_testBPerceivedTime += parseFloat(secs);
            temp_testBParticipantsPerceivedTime += 1;
          }

          if (data[item].q1 !== undefined) {
            temp_testBEOU += parseInt(data[item].q1);
            temp_testBParticipantsEOU += 1;
          }

          if (
            data[item].sus1 !== undefined &&
            data[item].sus2 !== undefined &&
            data[item].sus3 !== undefined &&
            data[item].sus4 !== undefined &&
            data[item].sus5 !== undefined &&
            data[item].sus6 !== undefined &&
            data[item].sus7 !== undefined &&
            data[item].sus8 !== undefined &&
            data[item].sus9 !== undefined &&
            data[item].sus10 !== undefined
          ) {
            let x = (parseInt(data[item].sus1) + parseInt(data[item].sus3) + parseInt(data[item].sus5) + parseInt(data[item].sus7) + parseInt(data[item].sus9)) - 5;
            let y = 25 - (parseInt(data[item].sus2) + parseInt(data[item].sus4) + parseInt(data[item].sus6) + parseInt(data[item].sus8) + parseInt(data[item].sus10));
            let sus = (x + y) * 2.5;
            // console.log('sss', sus.toFixed(0))
            temp_testBSUS += parseInt(sus.toFixed(0));
            // console.log('total', temp_testBSUS)
            temp_testBParticipantsSUS += 1;
          }
        }
      }

      setTestATimeTaken((temp_testATimeTaken / temp_testAParticipants).toFixed(0));
      setTestAPerceivedTime(
        (temp_testAPerceivedTime / temp_testAParticipantsPerceivedTime).toFixed(0)
      );
      setTestAEOU((temp_testAEOU / temp_testAParticipantsEOU).toFixed(0));
      setTestASUS((temp_testASUS / temp_testAParticipantsSUS).toFixed(1));
      let score = ((temp_testASUS / temp_testAParticipantsSUS).toFixed(1));
      if( score > 0 && score <= 25) {
        //worst
        setSUSColorA('worst');
      }
      if( score > 25 && score <= 51.6) {
        //poor
        setSUSColorA('poor');
      }
      if( score > 51.6 && score <= 71) {
        //ok
        setSUSColorA('ok');
      }
      if( score > 71 && score <= 80.7) {
        //good
        setSUSColorA('good');
      }
      if( score > 80.7 && score <= 84) {
        //Excellent
        setSUSColorA('excellent');
      }
      if( score > 84 && score <= 100) {
        //Best
        setSUSColorA('best');
      }
      
      
      setTestBTimeTaken((temp_testBTimeTaken / temp_testBParticipants).toFixed(0));
      setTestBPerceivedTime(
        (temp_testBPerceivedTime / temp_testBParticipantsPerceivedTime).toFixed(0)
      );
      setTestBEOU((temp_testBEOU / temp_testBParticipantsEOU).toFixed(0));
      setTestBSUS((temp_testBSUS / temp_testBParticipantsSUS).toFixed(1));
      
      let scoreB = ((temp_testBSUS / temp_testBParticipantsSUS).toFixed(1));
      if( scoreB > 0 && scoreB <= 25) {
        //worst
        setSUSColorB('worst');
      }
      if( scoreB > 25 && scoreB <= 51.6) {
        //poor
        setSUSColorB('poor');
      }
      if( scoreB > 51.6 && scoreB <= 71) {
        //ok
        setSUSColorB('ok');
      }
      if( scoreB > 71 && scoreB <= 80.7) {
        //good
        setSUSColorB('good');
      }
      if( scoreB > 80.7 && scoreB <= 84) {
        //Excellent
        setSUSColorB('excellent');
      }
      if( scoreB > 84 && scoreB <= 100) {
        //Best
        setSUSColorB('best');
      }
      

      setAverageOfItem1(Math.round(totalMoney1 / totalTest1));
      setNumberOfItem1Responses(totalTest1);

      setAverageOfItem2(Math.round(totalMoney2 / totalTest2));
      setNumberOfItem2Responses(totalTest2);

      setAverageOfItem2Actual(
        Math.round(
          totalMoney1 / totalTest1 + (totalMoney1 / totalTest1 / 100) * 62.5
        )
      );

      setUsers(usersArr);
      setNumberOfParticipants(total);
    });

    document.addEventListener("keydown", function (e) {
      switch (e.keyCode) {
        case 37:
          // alert("left");
          if (parseInt(currentSlideRef.current) > 1) {
            setCurrentSlide(parseInt(currentSlideRef.current) - 1);
          }
          break;
        case 39:
          // alert("right");
          if (
            parseInt(currentSlideRef.current) < parseInt(totalSlidesRef.current)
          ) {
            setCurrentSlide(parseInt(currentSlideRef.current) + 1);
          }
          break;
      }
    });
  }, []);

  useEffect(() => {
    setTotalSlides(refSlides.current.querySelectorAll("label").length);
  }, [refSlides]);

  useEffect(() => {
    update(slideNumberRef, { currentSlide });
    refSlides.current.querySelectorAll("label").forEach((element) => {
      element.style.display = "none";
    });
    refSlides.current.querySelector(
      'label[id="' + currentSlide + '"]'
    ).style.display = "block";

    if(currentSlide === 22) {
      setSurpressed(false);
    }else{
      setSurpressed(true);
    }
  }, [currentSlide]);

  const slides = (
    <div>
      <Slide id="1" variant="cover">
        <img src={require("./images/klogo.svg").default} />
        <h1>An Introduction to Product Design</h1>
      </Slide>
      <Slide id="2" title="Who are we?" contentVariant="middle">
        <div className={style.grid}>
          <Member
            name="Curtis Marsh"
            jobTitle="Product Designer"
            squad="SuperPicks"
            image="curtis.png"
          />
          <Member
            name="James Mifsud"
            jobTitle="Product Designer"
            squad="Front-end"
            image="james.png"
          />
          <Member
            name="Jamie O'Reilly"
            jobTitle="Product Designer"
            squad="Sportsbook"
            image="jamie.png"
          />
          <Member
            name="Light Balla"
            jobTitle="Product Designer"
            squad="Payments"
            image="light.png"
          />
          <Member
            name="Maha Hobeika"
            jobTitle="Product Designer"
            squad="Core / Platform"
            image="maha.png"
          />
          <Member
            name="Miguel Migneco"
            jobTitle="Product Designer"
            squad="Virtuals"
            image="miguel.png"
          />
          <Member
            name="Mike Spurr"
            jobTitle="Product Designer"
            squad="Casino / Agency"
            image="mike.png"
          />
          <Member
            name="Tom McCordall"
            jobTitle="Product Designer"
            squad="Head"
            image="tom.png"
          />
        </div>
      </Slide>

      <Slide id="3" title="What is Product Design?" contentVariant="twoSide">
        <div
          style={{
            fontSize: "40px",
            fontWeight: "300",
            lineHeight: "80px",
            marginTop: "-50px",
          }}
        >
          We're not Artists
          <br />
          We're Scientists
        </div>
        <div>
          <img src={require("./images/dontmakemethink.png")} />
        </div>
      </Slide>

      <Slide id="4" title="Let's Get Testing" contentVariant="twoSideMiddle">
        <div>
          <img src={require("./images/qr-code.svg").default} />
        </div>
        <div>
          <div style={{ fontSize: "40px" }}>Participants</div>{" "}
          <span style={{ fontSize: "150px" }}>{numberOfParticipants}</span>
        </div>
      </Slide>

      <Slide id="5" title="The Excercise" contentVariant="twoSideMiddle">
        <div>
          <img src={require("./images/qr-code.svg").default} />
        </div>
        <div className={style.alignLeft}>
          <p>
            On your mobile device, you will be presented with a simple
            registration form.
          </p>
          <p>
            Please complete the registration flow as if you were completing it
            for real.
          </p>
          <p>
            Once completed, you will be asked to complete a survey. Please
            complete all the questions.
          </p>
        </div>
      </Slide>

      <Slide id="6" title="Multidisciplinary Function" contentVariant="middle">
        <img src={require("./images/multidisciplinary.svg").default} />
      </Slide>

      <Slide id="7" title="The Process" contentVariant="middle">
        <img src={require("./images/process.svg").default} />
      </Slide>

      <Slide id="8" title="Testing Tools">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "32px",
            lineHeight: "60px",
            paddingLeft: "100px",
            paddingTop: "5%",
          }}
        >
          <ul>
            <li>Customer focus groups</li>
            <li>Maze surveys via Twitter influences</li>
            <li>Feedback from agents</li>
            <li>Competitive analysis</li>
            <li>
              Prototype testing via Maze
              <ul style={{ fontWeight: "300", marginBottom: "20px" }}>
                <li>System Usability score (SUS)</li>
                <li>Ease Of Use score (EOU)</li>
                <li>Time to completion</li>
              </ul>
            </li>
            <li>A/B testing on live site (Amplitude)</li>
            <li>Session replay (FullStory)</li>
          </ul>
        </div>
      </Slide>

      <Slide id="9" title="A/B Test Results" contentVariant="twoSideTop">
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            A
            <img src={require("./images/aTest.png")} />
          </div>
        </div>
        <div className={style.data}>
          
        </div>
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            B
            <img src={require("./images/bTest.png")} />
          </div>
        </div>
      </Slide>

      <Slide id="10" title="A/B Test Results" contentVariant="twoSideTop">
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            A
            <img src={require("./images/aTest.png")} />
          </div>
        </div>
        <div className={style.data}>
          <div>
            <div className={style.time}>
              <span>{testATimeTaken}s</span>
            </div>
            <div>Time</div>
            <div className={style.time}>
              <span>{testBTimeTaken}s</span>
            </div>
          </div>
        </div>
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            B
            <img src={require("./images/bTest.png")} />
          </div>
        </div>
      </Slide>

      <Slide id="11" title="A/B Test Results" contentVariant="twoSideTop">
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            A
            <img src={require("./images/aTest.png")} />
          </div>
        </div>
        <div className={style.data}>
          <div>
            <div className={style.time}>
              <span>{testATimeTaken}s</span>
            </div>
            <div>Time</div>
            <div className={style.time}>
              <span>{testBTimeTaken}s</span>
            </div>
          </div>
          <div>
            <div className={style.time}>
              <span>{testAPerceivedTime}s</span>
            </div>
            <div>Perceived</div>
            <div className={style.time}>
              <span>{testBPerceivedTime}s</span>
            </div>
          </div>
        </div>
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            B
            <img src={require("./images/bTest.png")} />
          </div>
        </div>
      </Slide>

      <Slide id="12" title="A/B Test Results" contentVariant="twoSideTop">
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            A
            <img src={require("./images/aTest.png")} />
          </div>
        </div>
        <div className={style.data}>
          <div>
            <div className={style.time}>
              <span>{testATimeTaken}s</span>
            </div>
            <div>Time</div>
            <div className={style.time}>
              <span>{testBTimeTaken}s</span>
            </div>
          </div>
          <div>
            <div className={style.time}>
              <span>{testAPerceivedTime}s</span>
            </div>
            <div>Perceived</div>
            <div className={style.time}>
              <span>{testBPerceivedTime}s</span>
            </div>
          </div>
          <div>
            <div className={style.eou}>{testAEOU}</div>
            <div>Ease of Use</div>
            <div className={style.eou}>{testBEOU}</div>
          </div>
        </div>
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            B
            <img src={require("./images/bTest.png")} />
          </div>
        </div>
      </Slide>

      <Slide id="13" title="A/B Test Results" contentVariant="twoSideTop">
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            A
            <img src={require("./images/aTest.png")} />
          </div>
        </div>
        <div className={style.data}>
          <div>
            <div className={style.time}>
              <span>{testATimeTaken}s</span>
            </div>
            <div>Time</div>
            <div className={style.time}>
              <span>{testBTimeTaken}s</span>
            </div>
          </div>
          <div>
            <div className={style.time}>
              <span>{testAPerceivedTime}s</span>
            </div>
            <div>Perceived</div>
            <div className={style.time}>
              <span>{testBPerceivedTime}s</span>
            </div>
          </div>
          <div>
            <div className={style.eou}>{testAEOU}</div>
            <div>Ease of Use</div>
            <div className={style.eou}>{testBEOU}</div>
          </div>
          <div>
            <div className={`${style.SUS} ${style[SUSColorA]}`}>{testASUS}</div>
            <div>SUS</div>
            <div className={`${style.SUS} ${style[SUSColorB]}`}>{testBSUS}</div>
          </div>
        </div>
        <div className={style.testImage}>
          <div className={style.imageContainer}>
            B
            <img src={require("./images/bTest.png")} />
          </div>
        </div>
      </Slide>

      <Slide id="14" title="Success Metrics" contentVariant="middle">
        <div>
          <h3>System Usability Scale</h3>
          <p style={{ width: 655 }}>
            SUS is a tool for measuring the usability of a digital journey.
            Users are asked to rate 10 statements from 1-5 (strongly disagree
            &gt; strongly agree). The results are then put through a simple
            algorithm which results in a score from 0-100.
          </p>
          <img src={require("./images/sus.svg").default} />
          <div>
            <div className={style.variant} style={{width:"calc("+testASUS+"% - 12px)"}}>Variant A</div>
            <div className={style.variant} style={{width:"calc("+testBSUS+"% - 12px)"}}>Variant B</div>
          </div>
        </div>
      </Slide>

      <Slide id="15" title="Nuggets" contentVariant="twoSide">
        <div
          style={{
            paddingLeft: "100px",
            paddingRight: "50px",
            fontSize: "24px",
          }}
        >
          <p>
            Having recently taken part in a Coglode course you will hear the
            Product Design team refer to “Nuggets”.
          </p>
          <p>
            Nuggets are bite-size behavioural insight that come in the form of a
            large deck of cards.
          </p>
          <p>
            We use these nuggets to help us design, ensuring we're maximising
            the potential based on what we're trying to help the customer do
          </p>
        </div>
        <div>
          <img src={require("./images/nuggets.png")} />
        </div>
      </Slide>

      <Slide id="16" title="IKEA Effect" contentVariant="twoSideMiddle">
        <div>
          <img src={require("./images/qr-code.svg").default} />
        </div>
        <div>
          <div style={{ fontSize: "40px" }}>Participants</div>{" "}
          <span style={{ fontSize: "150px" }}>{numberOfParticipants}</span>
        </div>
      </Slide>

      <Slide id="17" title="Value the Chair" contentVariant="twoSideMiddle">
        <div>
          <div className={style.chart}>
            <div className={style.yaxis}>
              <span>100%</span>
              <span>75%</span>
              <span>50%</span>
              <span>25%</span>
            </div>
            <div className={style.xaxis}>Responses</div>
            <div
              className={style.bar}
              style={{
                height:
                  (numberOfItem1Responses / numberOfParticipants) * 100 + "%",
              }}
            ></div>
          </div>
        </div>
        <div>
          <img src={require("./images/chair.png")} />
        </div>
      </Slide>

      <Slide
        id="18"
        title="...and in Flatpack form?"
        contentVariant="twoSideMiddle"
      >
        <div>
          <div className={style.chart}>
            <div className={style.yaxis}>
              <span>100%</span>
              <span>75%</span>
              <span>50%</span>
              <span>25%</span>
            </div>
            <div className={style.xaxis}>Responses</div>
            <div
              className={style.bar}
              style={{
                height:
                  (numberOfItem2Responses / numberOfParticipants) * 100 + "%",
              }}
            ></div>
          </div>
        </div>
        <div>
          <img src={require("./images/flatpack.png")} />
        </div>
      </Slide>

      <Slide id="19" title="The Results">
        <div className={style.bigChart}>
          <div className={style.yaxis}>
            <span>$500</span>
            <span>$400</span>
            <span>$300</span>
            <span>$200</span>
            <span>$100</span>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/chair.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem1 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem1}</span>
            </div>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/flatpack.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem2 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem2}</span>
            </div>
          </div>
        </div>
      </Slide>

      <Slide id="20" title="The Results">
        <div className={style.tableContainer}>
          <table>
            <tbody>
              {users
                ? users.map((u, index) => (
                    <tr key={index}>
                      <td>{u.name}</td>
                      <td>{u.test1 ? "$" + u.test1 : " "}</td>
                      <td className={style.middle}>
                        {u.test2 ? (
                          <span>
                            {u.test2 > u.test1 ? (
                              <span className={style.up}></span>
                            ) : (
                              <span className={style.down}></span>
                            )}
                          </span>
                        ) : (
                          " "
                        )}
                      </td>
                      <td>{u.test2 ? "$" + u.test2 : " "}</td>
                    </tr>
                  ))
                : false}
            </tbody>
          </table>
        </div>
        <div className={`${style.bigChart} ${style.list}`}>
          <div className={style.yaxis}></div>
          <div className={`${style.cols} ${style.list}`}>
            <div className={style.xaxis}>
              <img src={require("./images/chair.png")} />
            </div>
          </div>
          <div className={`${style.cols} ${style.list}`}>
            <div className={style.xaxis}>
              <img src={require("./images/flatpack.png")} />
            </div>
          </div>
        </div>
      </Slide>

      <Slide id="21" title="The Results">
        <div className={style.bigChart}>
          <div className={style.yaxis}>
            <span>$500</span>
            <span>$400</span>
            <span>$300</span>
            <span>$200</span>
            <span>$100</span>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/chair.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem1 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem1}</span>
            </div>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/flatpack.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem2 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem2}</span>
            </div>

            <div
              className={`${style.bar} ${style.bar2} ${surpressed && style.surpressed}`}
              style={{
                height: (averageOfItem2Actual / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem2Actual}</span>
            </div>
          </div>
        </div>
      </Slide>

      <Slide id="22" title="The Results">
        <div className={style.bigChart}>
          <div className={style.yaxis}>
            <span>$500</span>
            <span>$400</span>
            <span>$300</span>
            <span>$200</span>
            <span>$100</span>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/chair.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem1 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem1}</span>
            </div>
          </div>
          <div className={style.cols}>
            <div className={style.xaxis}>
              <img src={require("./images/flatpack.png")} />
            </div>
            <div
              className={style.bar}
              style={{
                height: (averageOfItem2 / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem2}</span>
            </div>

            <div
              className={`${style.bar} ${style.bar2} ${surpressed && style.surpressed}`}
              style={{
                height: (averageOfItem2Actual / 500) * 100 + "%",
              }}
            >
              <span>${averageOfItem2Actual}</span>
            </div>
          </div>
        </div>
      </Slide>

      <Slide
        id="23"
        title="Questions"
        contentVariant="middle"
        variant="noHeader"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              width: 855,
              fontSize: "36px",
              lineHeight: "60px",
              textAlign: "center",
              fontWeight: "300",
              fontStyle: "italic",
            }}
          >
            <q>
              The thing I'm most impressed with, in design meetings, is what did
              you{" "}
              <span style={{ color: "#fff", fontWeight: "600" }}>undesign</span>
              ? Undesigning is the best thing... Just delete it!
            </q>
            <div style={{ fontStyle: "normal", fontSize: "24px" }}>
              Elon Musk 2019
            </div>
          </p>
          <img
            src={require("./images/rockets.png")}
            style={{ width: "500px" }}
          />
        </div>
      </Slide>
    </div>
  );

  function onChangeHandler(e) {
    setCurrentSlide(e.target.value);
  }

  return (
    <div className={style.presentationContainer}>
      <div className={style.index} onChange={onChangeHandler}>
        <div className={style.actions}>
          <span onClick={() => openFullscreen(refSlides)}>Fullscreen</span>
        </div>
        {slides}
      </div>
      <div className={style.slidesContainer} ref={refSlides}>
        {slides}
      </div>
    </div>
  );
}

export default Presentation;
