import Header from "./Header";
import style from "./Layout.module.css"

function Layout(props) {
  return (
    <div className={style.contentContainer}>
      <Header showSettings={props.showSettings} showUsers={props.showUsers} />
      <div className={style.mainContent}>{props.children}</div>
    </div>
  );
}
export default Layout;
