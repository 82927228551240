import { useNavigate } from "react-router-dom";
import { ref, onValue, update, push } from "firebase/database";
import db from "../../../firebase/Firebase";
import UserContext from "../../user/User";
import Button from "../../molecules/buttons/Button";
import List from "../../organisms/tables/List";
import Journey from "./Journey";
import style from "./Journeys.module.css";
import { useContext, useEffect, useState } from "react";
import Fab from "../../molecules/buttons/Fab";
import Modal from "../../organisms/modals/Modal";
import TextField from "../../molecules/formElements/TextField";
import Select from "../../molecules/formElements/select/Select";

function Journeys() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const journeysRef = ref(db, "journeys");
  const [journeys, setJourneys] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  
  const d = new Date();
  const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  let month = d.getMonth();
  let year = d.getFullYear();

  const [newVersion, setNewVersion] = useState(months[month]+ " " +year);

  const journeyTemplate = {
    description: "Description of this journey",
    name: "Journey Title",
    version: {"new":{
      name: '',
      rows: [
        {
          columns: [
            {
              colspan: 1,
              content: "Sign-Up",
              subType: "Sign-Up",
            },
            {
              colspan: 1,
              content: "Activation",
              subType: "Activation",
            },
            {
              colspan: 1,
              content: "Adoption",
              subType: "Adoption",
            },
          ],
          displayOrder: 1,
          name: "Customer Life Cycle",
          type: "lifeCycle",
        },
        {
          columns: [
            {
              colspan: 1,
              content: "Step 1",
            },
            {
              colspan: 1,
              content: "Step 2",
            },
            {
              colspan: 1,
              content: "Step 3",
            },
          ],
          displayOrder: 2,
          name: "Journey",
          type: "text",
        },
        {
          columns: [
            {
              colspan: 1,
              content: "0",
              content2: "0",
              state: true,
            },
            {
              colspan: 1,
              content: "0",
              content2: "0",
              state: true,
            },
            {
              colspan: 1,
              content: "0",
              content2: "0",
              state: true,
            },
          ],
          displayOrder: 3,
          name: "Time",
          type: "time",
        },
        {
          columns: [
            {
              colspan: 1,
              imageName: "",
              selected: false,
            },
            {
              colspan: 1,
              imageName: "",
              selected: false,
            },
            {
              colspan: 1,
              imageName: "",
              selected: false,
            },
          ],
          displayOrder: 4,
          name: "Visual",
          type: "image",
        },
        {
          columns: [
            {
              colspan: 1,
              content: 100,
            },
            {
              colspan: 1,
              content: 100,
            },
            {
              colspan: 1,
              content: 100,
            },
          ],
          displayOrder: 5,
          name: "Conversion",
          type: "conversion",
        },
        {
          columns: [
            {
              colspan: 1,
              content: 0,
            },
            {
              colspan: 1,
              content: 0,
            },
            {
              colspan: 1,
              content: 0,
            },
          ],
          max: 20,
          displayOrder: 6,
          name: "Error Rate",
          type: "barChart",
        },
        {
          columns: [
            {
              colspan: 1,
              content: "",
            },
            {
              colspan: 1,
              content: "",
            },
            {
              colspan: 1,
              content: "",
            },
          ],
          displayOrder: 7,
          name: "Comments",
          type: "comments",
        },
      ],
    }},
    sus: {},
  };

  const [newSquad, setNewSquad] = useState("");
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (user) {
      if (!user.id) {
        navigate("/");
      }
      onValue(journeysRef, (snapshot) => {
        let data = snapshot.val();
        let tempData = [];
        for (let i in data) {
          data[i].key = i;

          let tempItems = [];
          for (let p in data[i].items) {
            data[i].items[p].key = p;
            tempItems.push(data[i].items[p]);

            let temp = [];
            for (let d in data[i].items[p].sus) {
              temp.push(data[i].items[p].sus[d]);
            }
            var sortedArray = temp.sort(callback);
            if (sortedArray.length) {
              sortedArray[sortedArray.length - 1].style = {
                width: 100,
                fontSize: "16px",
                fontWeight: "500",
              };
              data[i].items[p].meta1 = sortedArray[sortedArray.length - 1];
            } else {
              data[i].items[p].meta1 = { value: 0, adjective: "none" };
            }
          }
          data[i].items = tempItems;

          tempData.push(data[i]);
        }
        setJourneys(tempData);
      });
    }

    if (user.role === "view" || !user.role || user.role === undefined) {
      setIsAdmin(false);
    }
    if (user.role === "superUser") {
      setIsAdmin(true);
    }
    if (user.role === "admin") {
      setIsAdmin(true);
    }
  }, [user]);

  // function callback(a, b) {
  //   if (a.month < b.month) return -1;
  //   if (a.month > b.month) return 1;
  //   return 0;
  // }

  function callback(a, b) {
    if (reverse(a.month) < reverse(b.month)) return -1;
    if (reverse(a.month) > reverse(b.month)) return 1;
    return 0;
  }

  function reverse(date) {
    var d = date.split("-")
    return d.reverse().join("-");
  }

  function openJourney(id) {
    navigate(id);
  }

  function createNew() {
    setShowNewModal(true);
  }
  function closeNew() {
    setShowNewModal(false);
  }

  function saveNew() {
    if (newName && newSquad && newVersion) {
      journeyTemplate.name = newName;
      journeyTemplate.version.new.name = newVersion;
      const newJourneyRef = ref(db, "/journeys/" + newSquad + "/items");
      let newKey = push(newJourneyRef, journeyTemplate).then((data) => {
        navigate("/journeys/" + newSquad + "/" + data.key);
      });
      // console.log(newKey);
    } else {
      alert("Please choose a squad and asign a new and version name for your journey");
    }
  }

  function squadHandler(val) {
    setNewSquad(val);
  }
  function nameHandler(val) {
    setNewName(val.target.value);
  }
  function versionHandler(val) {
    setNewVersion(val.target.value);
  }

  return (
    <div className="container">
      {journeys.map((data, index) => (
        <div key={index} className={style.category}>
          <h2>{data.key}</h2>
          <List
            columnHeaders={[
              { name: "Journey Name", style: "name" },
              { name: "SUS" },
              { name: "" },
            ]}
            squad={data.key}
            data={data.items}
            onItemClick={openJourney}
          />
        </div>
      ))}
      {isAdmin ? <Fab name="Create New" icon="add" onClick={createNew} /> : ""}
      <Modal
        animate={showNewModal}
        actions={[
          { name: "Close", onClick: closeNew },
          { name: "Save", onClick: saveNew },
        ]}
        onClose={closeNew}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <Select
            label="Squad"
            values={[
              { name: "Sports", value: "Sports" },
              { name: "Core", value: "Core" },
              { name: "Virtuals", value: "Virtuals" },
              { name: "Payments", value: "Payments" },
              { name: "Casino", value: "Casino" },
              { name: "Agency", value: "Agency" },
              { name: "SuperPicks", value: "SuperPicks" },
            ]}
            onChange={squadHandler}
          />
          <TextField
            label="Journey name"
            placeholder=" "
            onChange={nameHandler}
          />
          <TextField
            label="Version name"
            value={newVersion}
            placeholder="month and year (e.g. Apr 2022)"
            onChange={versionHandler}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Journeys;
