import { ref, onValue, update, set, push } from "firebase/database";
import db from "../../../firebase/Firebase";
import { useContext, useEffect, useState } from "react";

import UserContext from "../../user/User";

import TextField from "../../molecules/formElements/TextField";
import Modal from "../../organisms/modals/Modal";
import style from "./Settings.module.css";
import Button from "../../molecules/buttons/Button";

function Settings(props) {
  const settingsRef = ref(db, "settings");
  const [productsData, setProductsData] = useState([]);
  const [newSquadName, setNewSquadName] = useState("");
  const [newOptionName, setNewOptionName] = useState("");
  const [newOptionWeight, setNewOptionWeight] = useState("");
  const [productModal, setProductModal] = useState(false);
  const [optionModal, setOptionModal] = useState(false);
  const [settingsLocation, setSettingsLocation] = useState("");

  useEffect(() => {
    onValue(settingsRef, (snapshot) => {
      let data = snapshot.val();
      let products = [];
      for (const setting in data) {
        if (setting === "products") {
          props.setProduct(data[setting]);
          for (const product in data[setting]) {
            data[setting][product].key = product;
            let productSettings = [];
            for (const productSetting in data[setting][product].settings) {
              data[setting][product].settings[productSetting].key =
                productSetting;
              let productSettingValues = [];
              for (const productSettingValue in data[setting][product].settings[
                productSetting
              ].values) {
                data[setting][product].settings[productSetting].values[productSettingValue].key = productSettingValue;
                productSettingValues.push(
                  data[setting][product].settings[productSetting].values[
                    productSettingValue
                  ]
                );
              }
              data[setting][product].settings[productSetting].values =
                productSettingValues;

              productSettings.push(
                data[setting][product].settings[productSetting]
              );
            }
            data[setting][product].settings = productSettings;
            products.push(data[setting][product]);
          }
        }
      }
      setProductsData(products);
    });
  }, [props.userDetails]);

  function addOption() {
    const settingRef = ref(db, "settings" + settingsLocation);
    push(settingRef, {
      name: newOptionName,
      weight: newOptionWeight,
    });
    openOptionModalHandler(false);
  }

  function openOptionModalHandler(loc) {
    if(loc) {
      setSettingsLocation(loc);
    }
    setOptionModal(!optionModal);
  }
  function openProductModalHandler() {
    setProductModal(!productModal);
  }

  function addSquad() {
    const productsRef = ref(db, "settings/products");
    push(productsRef, {
      name: newSquadName,
      settings: {
        strategicFitOptions: {
          name: "Strategic Fit Options",
        },
      },
    });
  }

  function newProductNameChangeHandler(e) {
    setNewSquadName(e.target.value);
  }
  function newOptionTextChangeHandler(e) {
    setNewOptionName(e.target.value);
  }
  function newOptionWeightChangeHandler(e) {
    setNewOptionWeight(e.target.value);
  }

  return (
    <Modal title="Settings" animate={props.animate} onClose={() => props.onClose()}>
      <ul className={style.settings}>
        <li>
          <div className={style.item}>
            Squads
            <span className={style.tools}>
              <span
                className="material-symbols-rounded"
                onClick={openProductModalHandler}
              >
                add
              </span>
            </span>
            <Modal
              title="Create Squad"
              animate={productModal}
              actions="hidden"
              onClose={openProductModalHandler}
            >
              <TextField
                label="Squad Name"
                onChange={newProductNameChangeHandler}
              />
              <div className="actions">
                <Button name="Create" onClick={addSquad} />
              </div>
            </Modal>
          </div>
          <ul>
            {productsData.map((product) => (
              <li key={product.key}>
                <div className={style.item}>
                  {product.name}
                  <span className={style.tools}>
                    <span className="material-symbols-rounded">delete</span>
                    <span className="material-symbols-rounded">edit</span>
                  </span>
                </div>
                <ul>
                  {product.settings.map((productSetting) => (
                    <li key={productSetting.key}>
                      <div className={style.item}>
                        {productSetting.name}
                        <span className={style.tools}>
                          <span
                            className="material-symbols-rounded"
                            onClick={() =>
                              openOptionModalHandler(
                                "/products/" +
                                  product.key +
                                  "/settings/" +
                                  productSetting.key +
                                  "/values"
                              )
                            }
                          >
                            add
                          </span>
                        </span>
                        <Modal
                          title="Create Option"
                          animate={optionModal}
                          actions="hidden"
                          onClose={() => openOptionModalHandler('')}
                        >
                          <TextField
                            label="Name"
                            onChange={newOptionTextChangeHandler}
                          />
                          <TextField
                            label="Weight"
                            onChange={newOptionWeightChangeHandler}
                          />
                          <div className="actions">
                            <Button name="Create" onClick={addOption} />
                          </div>
                        </Modal>
                      </div>
                      <ul>
                        {productSetting.values.map((option, index) => (
                          <li key={index}>
                            <div className={style.item}>
                              {option.name}
                              <span className={style.tools}>
                                <span className="material-symbols-rounded">
                                  delete
                                </span>
                                <span className="material-symbols-rounded">
                                  edit
                                </span>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </Modal>
  );
}

export default Settings;
