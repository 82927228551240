import { useEffect, useRef, useState } from "react";
import { ref, onValue, update, set, push } from "firebase/database";
import db from "../../../firebase/Firebase";

import Modal from "../../organisms/modals/Modal";
import style from "./Users.module.css";
import TextField from "../../molecules/formElements/TextField";
import Button from "../../molecules/buttons/Button";
import Select from "../../molecules/formElements/select/Select";

function Users(props) {
  const emailRef = useRef();
  const dbRef = ref(db, "users");
  const productRef = ref(db, "settings/products");
  const [allUsers, setAllUsers] = useState([]);
  const [products, setProducts] = useState([]);

  const [key, setKey] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [product, setProduct] = useState("");

  useEffect(() => {
    onValue(dbRef, (snapshot) => {
      let data = snapshot.val();
      let tempUsers = [];
      for (const user in data) {
        tempUsers.push(data[user]);
      }
      setAllUsers(tempUsers);
    });

    onValue(productRef, (snapshot) => {
      let data = snapshot.val();
      let tempProducts = [];
      for (const product in data) {
        data[product].value = product;
        tempProducts.push(data[product]);
      }
      setProducts(tempProducts);
    });
  }, []);

  function emailChangeHandler(e) {
    setEmail(e.currentTarget.value);
    let str = e.currentTarget.value;
    str = str.replaceAll('@', '-at-');
    str = str.replaceAll('.', '-');
    setKey(str);
  }
  function nameChangeHandler(e) {
    setName(e.currentTarget.value);
  }
  function roleChangeHandler(e) {
    setRole(e);
  }
  function productChangeHandler(e) {
    setProduct(e);
  }

  function addUser() {
    if (email && name && key && role && product) {
      let initials = name.match(/\b(\w)/g);
      initials = initials.join('');

      const userRef = ref(db, "users/" + key);
      set(userRef, {
        displayName: name,
        email: email,
        role: role,
        squad: product,
        initials: initials
      })
    }else{
      alert('please complete all fields');
    }
  }

  return (
    <Modal
      title="Users"
      animate={props.animate}
      onClose={() => props.onClose()}
    >
      <div className={style.contents}>
        <div className={style.users}>
          {allUsers.map((user, index) => (
            <div key={index} className={style.user}>
              {user.displayName}
              <div className={style.email}>{user.email}</div>
            </div>
          ))}
        </div>
        <div className={style.addUser}>
          <TextField placeholder="example@company.com" label="Email" onChange={(e) => emailChangeHandler(e)} />
          <TextField placeholder="" label="Display Name" onChange={(e) => nameChangeHandler(e)} />
          <Select label="Role" values={[{name:'Viewer',value:"view"}, {name:'Editor',value:"admin"}]} onChange={(e) => roleChangeHandler(e)} />
          <Select label="Product" values={products} onChange={(e) => productChangeHandler(e)} />
          <Button name="Add New" onClick={addUser} />
        </div>
      </div>
    </Modal>
  );
}

export default Users;
