import { useState } from "react";

import style from "./EmailField.module.css";

function EmailField(props) {
  const [error, setError] = useState(null);

  function focusHandler(event) {
    event.currentTarget.querySelector("input").focus();
  }

  function blurHandler(event) {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
  }

  function changeHandler(event) {
    if (error) {
      if (!isValidEmail(event.target.value)) {
        setError("Please check your email");
      } else {
        setError(null);
      }
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <div className={`${style.textFieldContainer} ${error && style.error}`}>
      <div className={style.textField} onClick={focusHandler}>
        <input
          name={props.name}
          type={props.textFieldType}
          placeholder={props.placeholder}
          value={props.value}
          onBlur={blurHandler}
          onChange={changeHandler}
          ormnovalidate="formnovalidate"
        />
        <label>{props.label}</label>
      </div>
      {error && <div className={style.errorMessage}>{error}</div>}
    </div>
  );
}

export default EmailField;
