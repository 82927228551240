import { useEffect, useState } from "react";
import RadioTabs from "../../molecules/formElements/radioButtons/RadioTabs";
import ChipSelector from "../../molecules/formElements/select/ChipSelector";
import Slider from "../../molecules/sliders/Slider";
import style from "./aside.module.css";
import { update, onValue } from "firebase/database";

const ICECS = (props) => {
  const [impactICECS, setImpactICECS] = useState(0.5);
  const [confidenceICECS, setConfidenceICECS] = useState(0);
  const [effortICECS, setEffortICECS] = useState(0.5);
  const [complexityICECS, setComplexityICECS] = useState(0);
  const [strategicFitICECS, setStrateficFitICECS] = useState(0);
  const [strategicItemsICECS, setStrateficItemsICECS] = useState(0);

  const [possibleStrategicItems, setPossibleStrategicItems] = useState(
    props.possibleStrategicItems
  );

  const impactScoringScale = [
    { key: 1, name: "Massive", value: 3 },
    { key: 2, name: "High", value: 2 },
    { key: 3, name: "Medium", value: 1 },
    { key: 4, name: "Low", value: 0.5 },
    { key: 5, name: "Minimal", value: 0.25 },
  ];

  const effortScoringScale = [
    { key: 1, name: "S", value: 0.5 },
    { key: 2, name: "M", value: 1 },
    { key: 3, name: "L", value: 2 },
    { key: 4, name: "XL", value: 3 },
    { key: 5, name: "XXL", value: 5 },
  ];

  useEffect(() => {
    if (props.initiativeRef) {
      onValue(props.initiativeRef, (snapshot) => {
        const data = snapshot.val();
        setImpactICECS(data.impactICECS);
        setComplexityICECS(data.complexityICECS);
        setEffortICECS(data.effortICECS);
        setConfidenceICECS(data.confidenceICECS);

        let itemArr = [];
        if (data.strategicItemsICECS) {
          itemArr = data.strategicItemsICECS.split(",");
        }
        let possibleOptionsTemp = [];
        let defaultListClone = { ...possibleStrategicItems };

        for (const key in defaultListClone) {
          let checked = false;
          if (itemArr.includes(defaultListClone[key].key)) {
            checked = true;
          }
          defaultListClone[key].checked = checked;
          defaultListClone[key].value = defaultListClone[key].key;
          possibleOptionsTemp.push({
            ...defaultListClone[key],
          });
        }
        setPossibleStrategicItems(possibleOptionsTemp);
      });
    }
  }, [props.initiativeRef]);

  function impactChangeHandler(val) {
    if (!props.isNew) {
      update(props.initiativeRef, {
        impactICECS: val,
      });
    } else {
      setImpactICECS(val);
    }
  }

  function complexityChangeHandler(val) {
    if (!props.isNew) {
      update(props.initiativeRef, {
        complexityICECS: val,
      });
    } else {
      setComplexityICECS(val);
    }
  }

  function effortChangeHandler(val) {
    if (!props.isNew) {
      update(props.initiativeRef, {
        effortICECS: val,
      });
    } else {
      setEffortICECS(val);
    }
  }

  function confidenceChangeHandler(val) {
    if (!props.isNew) {
      update(props.initiativeRef, {
        confidenceICECS: val,
      });
    } else {
      setConfidenceICECS(val);
      
    }
  }

  function strategicFitChangeHandler(val) {
    if (!props.isNew) {
      update(props.initiativeRef, {
        strategicFitICECS: val.length,
        strategicItemsICECS: val.join(","),
      });
    } else {
      setStrateficFitICECS(val.length);
      setStrateficItemsICECS(val.join(","));
      
    }
  }


  useEffect(() => {
    if (props.isNew) {
      updateICECS();
    }
  }, [impactICECS, complexityICECS, effortICECS, confidenceICECS, strategicItemsICECS]);


  function updateICECS() {
    let vals = {
      impactICECS: impactICECS,
      complexityICECS: complexityICECS,
      effortICECS: effortICECS,
      confidenceICECS: confidenceICECS,
      strategicFitICECS: strategicFitICECS,
      strategicItemsICECS: strategicItemsICECS,
    };
    props.updateICECS(vals);
  }

  return (
    <div className={`${style.asideInner} ${props.show && style.show}`}>
      <div>
        <h2>Impact</h2>
        <div className={style.helper}>
          How much impact will this initiative have on the audience you selected
          in Reach?
        </div>
        <RadioTabs
          selected={impactICECS}
          tabGroup="impactICECS"
          tabs={impactScoringScale}
          onChange={impactChangeHandler}
        />
      </div>
      <div>
        <h2>Complexity</h2>
        <div className={style.helper}>
          How many dependencies are related to this initiative?
        </div>
        <Slider
          min="0"
          max="100"
          value={complexityICECS}
          onChange={complexityChangeHandler}
        />
      </div>

      <div>
        <h2>Effort</h2>
        <div className={style.helper}>
          How many person months will it take to complete this work?
        </div>
        <RadioTabs
          selected={effortICECS}
          tabGroup="effortICECS"
          tabs={effortScoringScale}
          onChange={effortChangeHandler}
        />
      </div>

      <div>
        <h2>Confidence</h2>
        <div className={style.helper}>
          How much confidence do you have in these values?
        </div>
        <Slider
          min="0"
          max="100"
          value={confidenceICECS}
          onChange={confidenceChangeHandler}
        />
      </div>

      <div>
        <h2>Strategic Fit</h2>
        <ChipSelector
          possibleOptions={possibleStrategicItems}
          onChange={strategicFitChangeHandler}
        />
      </div>
    </div>
  );
};

export default ICECS;
