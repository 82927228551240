import { useEffect, useState, useContext } from "react";
import { ref, push } from "firebase/database";

import UserContext from "../../user/User";

import db from "../../../firebase/Firebase";

import RadioTabs from "../../molecules/formElements/radioButtons/RadioTabs";
import ChipSelector from "../../molecules/formElements/select/ChipSelector";
import Select from "../../molecules/formElements/select/Select";
import ContentEditable from "react-contenteditable";

import Slider from "../../molecules/sliders/Slider";
import Modal from "../../organisms/modals/Modal";
import style from "./InitiativeDetails.module.css";
// import DateRangePicker from "../../molecules/formElements/datePickers/dateRangePicker/DateRangePicker";
import ColorPicker from "../../molecules/formElements/colorPicker/ColorPicker";
import Button from "../../molecules/buttons/Button";

function NewInitiative(props) {
  const user = useContext(UserContext);

  // const [initiativeRef, setInitiativeRef] = useState(
  //   ref(db, "initiatives/")
  // );
  const initiativeRef = ref(db, "initiatives/");

  const [title, setTitle] = useState("");
  const [squad, setSquad] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState("rgb(218, 233, 233)");
  const [reach, setReach] = useState(0);
  const [impact, setImpact] = useState(0.5);
  const [confidence, setConfidence] = useState(0);
  const [effort, setEffort] = useState(0.5);
  const [complexity, setComplexity] = useState(0.5);
  // const [num, setNum] = useState(0);
  const [description, setDescription] = useState("");
  const [strategicFit, setStrategicFit] = useState(0);
  const [strategicItems, setStrategicItems] = useState("");
  const [possibleStrategicItems, setPossibleStrategicItems] = useState();

  // const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

  // const [usersSquads, setUsersSquads] = useState(userDetails.squads);
  const [squads, setSquads] = useState([]);
  const [statuses, setStatuses] = useState([
    { key: 1, name: "Active", value: "Active" },
    { key: 2, name: "On Hold", value: "onHold" },
    { key: 3, name: "Completed", value: "Complete" },
  ]);

  useEffect(() => {
    if (user) {
      let possibleSquadsTemp = [];
      if (user.possibleSquads) {
        for (let squad in user.possibleSquads) {
          let selected = '';
          
          if(user.squad === user.possibleSquads[squad].key) {
            selected = 'selected'
          }
          possibleSquadsTemp.push({
            key: user.possibleSquads[squad].key,
            name: user.possibleSquads[squad].name,
            value: user.possibleSquads[squad].key,
            selected: selected,
          });
        }
      }
      setSquads(possibleSquadsTemp);
      setSquad(user.squad);
      // setUsersSquads(user.squad);
    }
  }, [user]);


  useEffect(() => {
    if (user.possibleSquads) {
      for (let squadInst in user.possibleSquads) {
        if(user.possibleSquads[squadInst].key === squad) {
          setPossibleStrategicItems(user.possibleSquads[squadInst].settings[0].values);
        }
      }
    }
  }, [user,squad]);


  let scoringScale = [
    { key: 1, name: "0.5", value: 0.5 },
    { key: 2, name: "1", value: 1 },
    { key: 3, name: "2", value: 2 },
    { key: 4, name: "3", value: 3 },
    { key: 5, name: "5", value: 5 },
  ];

  // function doNothing() {
  //   return false;
  // }

  function titleChangeHandlerNew(e) {
    setTitle(e.target.value);
  }
  function descriptionChangeHandlerNew(e) {
    setDescription(e.target.value);
  }
  function squadsChangeHandlerNew(val) {
    setSquad(val);
  }
  function statusChangeHandlerNew(val) {
    setStatus(val);
  }
  function colorChangeHandlerNew(val) {
    setColor(val);
  }
  function reachChangeHandlerNew(val) {
    setReach(val);
  }
  function impactChangeHandlerNew(val) {
    setImpact(val);
  }
  function confidenceChangeHandlerNew(val) {
    setConfidence(val);
  }
  function effortChangeHandlerNew(val) {
    setEffort(val);
  }
  function complexityChangeHandlerNew(val) {
    setComplexity(val);
  }
  function strategicFitChangeHandlerNew(val) {
    setStrategicFit(val.length);
    setStrategicItems(val.join(","));
  }

  function createNew() {
    if (!title || !squad || !status) {
      alert(
        "Please ensure you have:\r\n - an Initiative Title\r\n - a Squad\r\n - and a Status\r\n to create a new initiative"
      );
      return false;
    }
    let initiative = {
      color: color,
      name: title,
      squad: squad,
      status: status,
      description: description,
      reach: reach,
      impact: impact,
      confidence: confidence,
      effort: effort,
      complexity: complexity,
      strategicFit: strategicFit,
      strategicItems: strategicItems,
    };
    push(initiativeRef, initiative).then((data) => {
      initiative.id = data.key;
      props.onClose(initiative);
      alert(
        "Your initiative had been saved.  Changes will auto save from now on."
      );
      setTitle("");
      setSquad(false);

      let tempStatuses = [];
      statuses.forEach((st) => {
        st.selected = false;
        tempStatuses.push(st);
      });
      setStatuses(tempStatuses);

      setStatus(false);
      setColor("rgb(218, 233, 233)");
      setReach(0);
      setImpact(0.5);
      setConfidence(0);
      setEffort(0.5);
      setComplexity(0.5);
      // setNum(0);
      setDescription("");
      setStrategicFit(0);
      setStrategicItems("");
    });
  }

  return (
    <Modal
      animate={props.animate}
      onClose={props.onClose}
      variant={["wrapperOnly", "large"]}
    >
      <div className={style.initiativeDetails}>
        <main>
          <div className={style.title}>
            <ColorPicker color={color} onChange={colorChangeHandlerNew}>
              -
            </ColorPicker>
            <ContentEditable
              html={title}
              className={style.titleInner}
              placeholder={"Initiative Title"}
              onChange={titleChangeHandlerNew}
            />
          </div>
          <div className={style.row}>
            <div>
              <h2>Settings</h2>
              <div className={style.row}>
                <Select
                  label="Squad"
                  values={squads}
                  onChange={squadsChangeHandlerNew}
                  placeholder=" "
                />
                <Select
                  label="Status"
                  values={statuses}
                  onChange={statusChangeHandlerNew}
                  placeholder=" "
                />
              </div>
            </div>
          </div>
          <div className={style.descriptionContainer}>
            <h2>Description</h2>
            <ContentEditable
              html={description}
              className={style.description}
              placeholder={"Initiative Description"}
              onChange={descriptionChangeHandlerNew}
            />
          </div>
          <div className={style.actions}>
            <Button name="Cancel" />
            <Button name="Save New" onClick={createNew} />
          </div>
        </main>
        <aside>
          <div className={style.asideInner}>
            <div>
              <h2>Reach</h2>
              <div className={style.helper}>
                The percentage of users affected by this initiative across the
                whole customer base
              </div>
              <Slider
                min="0"
                max="100"
                value={reach}
                onChange={reachChangeHandlerNew}
              />
            </div>
            <div>
              <h2>Impact</h2>
              <div className={style.helper}>
                How much impact will this initiative have on the audience you
                selected in Reach?
              </div>
              <RadioTabs
                selected={impact}
                tabGroup="impactNew"
                tabs={scoringScale}
                onChange={impactChangeHandlerNew}
              />
            </div>
            <div>
              <h2>Confidence</h2>
              <div className={style.helper}>
                How much confidence do you have in these values?
              </div>
              <Slider
                min="0"
                max="100"
                value={confidence}
                onChange={confidenceChangeHandlerNew}
              />
            </div>
            <div>
              <h2>Effort</h2>
              <div className={style.helper}>
                How many person months will it take to complete this work?
              </div>
              <RadioTabs
                selected={effort}
                tabGroup="effortNew"
                tabs={scoringScale}
                onChange={effortChangeHandlerNew}
              />
            </div>
            <div>
              <h2>Complexity</h2>
              <div className={style.helper}>
                How many dependencies are related to this initiative?
              </div>
              <RadioTabs
                selected={complexity}
                tabGroup="complexityNew"
                tabs={scoringScale}
                onChange={complexityChangeHandlerNew}
              />
            </div>
            <div>
              <h2>Strategic Fit</h2>
              <ChipSelector
                possibleOptions={possibleStrategicItems}
                onChange={strategicFitChangeHandlerNew}
              />
            </div>
          </div>
        </aside>
      </div>
    </Modal>
  );
}

export default NewInitiative;
