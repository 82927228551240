import { useEffect, useState } from "react";
import style from "./Nugget.module.css";

function Nugget(props) {
  const [hide, setHide] = useState(true);
  const [nuggetData, setNuggetData] = useState(false);
  const [nugget, setNugget] = useState(false);

  useEffect(() => {
    setNugget(props.name);
  }, [props.name]);

  useEffect(() => {
    if (nugget) {
      setNuggetData(require("./data/" + nugget + ".json"));
    }
  }, [nugget]);

  function showHandler() {
    setHide(!hide);
  }

  function removeNugget(e, ref) {
    e.stopPropagation();
    props.removeNugget(ref);
  }

  function addNugget(e, ref) {
    e.stopPropagation();
    props.addNugget(ref);
  }

  if (!nuggetData) {
    return false;
  }

  return (
    <div className={style.nuggetContainer}>
      {props.state === "added" ? (
        <div
          className={style.remove}
          onClick={(e) => removeNugget(e, nuggetData.ref)}
        >
          -
        </div>
      ) : (
        <div
          className={style.add}
          onClick={(e) => addNugget(e, nuggetData.ref)}
        >
          +
        </div>
      )}
      <div
        className={`${style.nugget} ${style[nuggetData.categoryRef]} ${
          hide && style.collapsed
        } ${style[props.variant]} ${style[props.state]}`}
        onClick={showHandler}
      >
        {nuggetData.number}
        <img
          className={style.thumbnail}
          src={require("./images/" +
            nuggetData.ref +
            "/" +
            nuggetData.thumbnail)}
        />
        {nuggetData.name}
        <div className={`${style.fullNugget} ${hide && style.minimised}`}>
          <div className={style.nuggetLarge}>
            <div className={style.top}>
              <div
                className={style.category}
                style={{ color: nuggetData.secondaryColor }}
              >
                {nuggetData.category}
              </div>
              <div
                className={style.number}
                style={{ backgroundColor: nuggetData.secondaryColor }}
              >
                {nuggetData.number}
              </div>
              <div
                className={style.category}
                style={{ color: nuggetData.secondaryColor }}
              >
                Coglode.com
              </div>
            </div>
            <div className={style.name}>{nuggetData.name}</div>
            <div className={style.teaser}>{nuggetData.teaser}</div>
            <div className={style.image}>
              <img
                src={require("./images/" +
                  nuggetData.ref +
                  "/" +
                  nuggetData.logo)}
              />
            </div>
            <div
              className={style.summary}
              style={{ color: nuggetData.secondaryColor }}
            >
              {nuggetData.summary}
            </div>
            <div className={style.source}>{nuggetData.source}</div>
          </div>
          <div className={style.nuggetLarge}>
            <div className={style.backTop}>
              {nuggetData.theStudy ? (
                <>
                  <div
                    className={style.title}
                    style={{ color: nuggetData.secondaryColor }}
                  >
                    The study
                  </div>
                  <div className={style.details}>
                    <div
                      dangerouslySetInnerHTML={{ __html: nuggetData.theStudy }}
                    ></div>
                    <div>
                      {nuggetData.theStudyImage ? (
                        <img
                          src={require("./images/" +
                            nuggetData.ref +
                            "/" +
                            nuggetData.theStudyImage)}
                        />
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </>
              ) : (
                false
              )}
            </div>
            <div className={style.backBottom}>
              <div
                className={style.title}
                style={{ color: nuggetData.secondaryColor }}
              >
                Key takeaways
              </div>
              <div className={style.details}>
                <div
                  dangerouslySetInnerHTML={{ __html: nuggetData.keyTakeaways }}
                ></div>
                <div>
                  {nuggetData.detailsImages.map((i, index) => (
                    <img
                      key={index}
                      src={require("./images/" + nuggetData.ref + "/" + i)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nugget;
