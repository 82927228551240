import { useState, useEffect, useRef } from "react";

import style from "./ColorPicker.module.css";

function ColorPicker(props) {
  const [animate, setAnimate] = useState(false);
  const [color, setColor] = useState('');
  const ref = useRef(null);
  const { onClickOutside } = props;
  const [colorsArr, setColorsArr] = useState([
    { key: 1, color: "rgb(218, 233, 233)" },
    { key: 2, color: "rgb(233, 233, 200)" },
    { key: 3, color: "rgb(215, 233, 220)" },
    { key: 4, color: "rgb(215, 224, 233)" },
    { key: 5, color: "rgb(215, 215, 233)" },
    { key: 6, color: "rgb(228, 215, 233)" },
    { key: 7, color: "rgb(233, 215, 233)" },
    { key: 8, color: "rgb(233, 225, 215)" },
    { key: 9, color: "rgb(233, 215, 215)" },
  ]);

  useEffect(() => {
    setColor(props.color);
  },[props]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAnimate(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  function show() {
    setAnimate(true);
  }

  function colorClicked(color) {
    props.onChange(color);
    setColor(color);
    setAnimate(false);
  }

  return (
    <>
      <div className={style.color}>
        <div
          className={style.current}
          style={{ backgroundColor: color }}
          onClick={show}
        >
          {props.children}
        </div>
        <div
          ref={ref}
          className={`${style.colorPicker} ${animate && style.show}`}
        >
          {colorsArr.map((col) => (
            <div key={col.key} style={{ backgroundColor: col.color }} onClick={() => colorClicked(col.color)}></div>
          ))}
        </div>
      </div>
    </>
  );
}
export default ColorPicker;
