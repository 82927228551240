import style from "./List.module.css";

function List(props) {
  return (
    <div>
      <table className={style.list} cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {props.listData.headings.map((column, index) => (
              <th key={index} className={`${column.left && style.left}`}>{column.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.listData.rows.map((row, index) => (
            <tr key={index} onClick={() => props.itemClick(row.id)}>
              {row.columns.map((column, index2) => (
                <td key={index2} className={`${column.left && style.left} ${column.bold && style.bold}`}>{column.value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default List;
