import ContentEditable from "react-contenteditable";
import style from "./BarChart.module.css";

function BarChart(props) {

  function changeHandler(e) {
    let tempVal = e.target.value;

    if (isNaN(e.target.value)) {
      return false;
    }
    if(!e.target.value) {
      tempVal = 0;
    }
    
    if(parseFloat(e.target.value) > 100) {
      tempVal = 100;
    }
    if(parseFloat(e.target.value) < 0) {
      tempVal = 0;
    }
    e.target.value = parseFloat(tempVal).toFixed(2);
    
    props.onChange(e);
  }

  function pressed(e) {
    // console.log(e.keyCode);
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39 && e.keyCode !== 9  && e.keyCode !== 190) {
      e.preventDefault();
      return false;
    }
  }


  return (
    <div className={style.barChartCell}>
      <div
        className={style.bar}
        style={{ height: (props.data.content / props.max) * 100 + "%" }}
      ></div>
      <div className={style.num}>
        <ContentEditable
          html={"" + props.data.content}
          onChange={(e) => changeHandler(e)}
          tagName="span"
          onKeyDown={(e) => pressed(e)}
          disabled={props.disabled}
        />
        %
      </div>
    </div>
  );
}
export default BarChart;
