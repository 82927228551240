import style from "./Button.module.css";

function Button(props) {
  return (
    <button onClick={props.onClick} className={style[props.variant]}>
      {props.icon && (
        <span className="material-symbols-rounded">{props.icon}</span>
      )}{" "}
      {props.name}
    </button>
  );
}

export default Button;
