import style from "./SUS.module.css";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { ref, set } from "firebase/database";
import db from "../../../firebase/Firebase";

import Modal from "../../organisms/modals/Modal";
import Button from "../../molecules/buttons/Button";

function SUS(props) {
  const allowedExtensions = ["csv"];
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [SUScores, setSUScores] = useState([]);
  const [file, setFile] = useState("");
  const [SUS, setSUS] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filename, setFilename] = useState("Choose CSV file");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let temp = [];
    for (let i in props.scores) {
      temp.push(props.scores[i]);
    }

    var sortedArray = temp.sort(callback);
    // var sortedArray = temp;

    setSUScores(sortedArray);
  }, [props.scores]);

  function callback(a, b) {
    var aDate = a.month.split("-");
    var bDate = b.month.split("-");
    var newADate = new Date( aDate[2], aDate[1] - 1, aDate[0]);
    var newBDate = new Date( bDate[2], bDate[1] - 1, bDate[0]);
    
    // if (reverse(a.month) < reverse(b.month)) return -1;
    // if (reverse(a.month) > reverse(b.month)) return 1;
    if (newADate < newBDate) return -1;
    if (newADate > newBDate) return 1;
    return 0;
  }

  function reverse(date) {
    var d = date.split("-");
    return d.reverse().join("-");
  }

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      } else {
        var fileName = "";
        fileName = e.target.value.split("\\").pop();
        setFilename(fileName);
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      // {console.log(parsedData)}
      // const columns = Object.keys(parsedData[0]);
      // setData(parsedData);
      let totalSUS = [];
      let completedUsers = [];
      let userResponses = [];
      let month = 0;
      parsedData.map((user, index) => {
        let uOdd = 0;
        let uEven = 0;
        let uSUS = 0;
        if (user.completedAt && user.completedAt !== "Not finished") {
          let date = new Date(parseInt(user.completedAt));
        // if (JSON.parse(user.meta).completedAt && JSON.parse(user.meta).completedAt !== "Not finished") {
        //   let date = new Date(JSON.parse(user.meta).completedAt);
          let month = date.getMonth();
          let datePrint = month + 1 + "-01-" + date.getFullYear();

          // console.log(date.getDate() + " / " + (date.getMonth() + 1) + " / " + date.getFullYear())
          if (typeof completedUsers[month] === "undefined") {
            completedUsers[month] = 0;
          }
          completedUsers[month] += 1;
          uOdd += parseInt(
            user[
              "[2] [scale] 1. I think that I would like to use BetKing frequently."
            ]
          );
          uOdd += parseInt(
            user["[4] [scale] 3. I thought BetKing was easy to use."]
          );
          uOdd += parseInt(
            user[
              "[6] [scale] 5. I found the various functions in BetKing were well integrated."
            ]
          );
          uOdd += parseInt(
            user[
              "[8] [scale] 7. I would imagine that most people would learn to use BetKing very quickly."
            ]
          );
          uOdd += parseInt(
            user["[10] [scale] 9. I felt very confident using BetKing."]
          );

          uEven += parseInt(
            user["[3] [scale] 2. I found BetKing unnecessarily complex."]
          );
          uEven += parseInt(
            user[
              "[5] [scale] 4. I think that I would need the support of a technical person to be able to use BetKing."
            ]
          );
          uEven += parseInt(
            user[
              "[7] [scale] 6. I thought there was too much inconsistency in BetKing."
            ]
          );
          uEven += parseInt(
            user["[9] [scale] 8. I found BetKing very awkward to use."]
          );
          uEven += parseInt(
            user[
              "[11] [scale] 10. I needed to learn a lot of things before I could get going with BetKing."
            ]
          );

          uOdd = uOdd - 5;
          uEven = 25 - uEven;
          uSUS = (uOdd + uEven) * 2.5;
          userResponses.push({
            sus: uSUS,
            data: user["[12] [input] What can we do to improve your experience?"],
          });
          // console.log("uOdd", uOdd);
          // console.log("uEven", uEven);
          // console.log("uSUS", uSUS);
          // console.log("user", user);
          if (typeof totalSUS[month] === "undefined") {
            totalSUS[month] = { score: 0 };
          }
          totalSUS[month].score = totalSUS[month].score + uSUS;
          totalSUS[month].datePrint = datePrint;
          totalSUS[month].year = date.getFullYear();
        }
      });

      // console.log(userResponses);

      let prompt = {
        prompt:
          "Do not include any explanations, only provide a RFC8259 compliant JSON response following this format without deviation. Using the following data by adding a cell 'category' that categorizes the feedback in cell 'data'.  Ignore false, blank or null entries: " +
          JSON.stringify(userResponses),
      };

      const options = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prompt),
      };
      // console.log(options);
      // fetch("http://localhost:8080/chat", options)
      //   .then((response) => response.json())
      //   .then((data) => handleDataResponse(data));


      //   function handleDataResponse(data) {
      //     console.log(data);
      //     console.log(JSON.parse(data.message.content));
      //   }

      totalSUS.map((m, index) => {
        let tempAdjective = "";
        let tempScore = (m.score / completedUsers[index]).toFixed(1);

        if (tempScore > 0 && tempScore <= 25) {
          tempAdjective = "Worst";
        }
        if (tempScore > 25 && tempScore <= 51.6) {
          tempAdjective = "Poor";
        }
        if (tempScore > 51.6 && tempScore <= 71) {
          tempAdjective = "OK";
        }
        if (tempScore > 71 && tempScore <= 80.7) {
          tempAdjective = "Good";
        }
        if (tempScore > 80.7 && tempScore <= 84) {
          tempAdjective = "Excellent";
        }
        if (tempScore > 84 && tempScore <= 100) {
          tempAdjective = "Best";
        }

        totalSUS[index] = {
          datePrint: m.datePrint,
          year: m.year,
          score: tempScore,
          users: completedUsers[index],
          adjective: tempAdjective,
        };
      });
      setSUS(totalSUS);
    };
    reader.readAsText(file);
  };

  function newSUS() {
    setShowModal(true);
  }
  function closeNewSUS() {
    setShowModal(false);
  }

  function saveSUS(date, score, adjective, users) {
    let susRef = ref(
      db,
      "journeys/" + props.squad + "/items/" + props.id + "/sus/" + date
    );
    set(susRef, {
      adjective: adjective,
      month: date,
      value: score,
      users: users,
    });
    closeNewSUS();
  }

  return (
    <>
      <div className={style.susHistory} onClick={newSUS}>
        <div className={style.title}>SUS</div>
        {SUScores.length ? (
          <>
            {SUScores.length === 1 && (
              <>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
              </>
            )}
            {SUScores.length === 2 && (
              <>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
              </>
            )}
            {SUScores.length === 3 && (
              <>
                <div className={style.bar}></div>
                <div className={style.bar}></div>
              </>
            )}
            {SUScores.length === 4 && (
              <>
                <div className={style.bar}></div>
              </>
            )}
            {SUScores.map((score, index) => (
              <div
                key={index}
                className={`${style[score.adjective]} ${style.bar}`}
                style={{ height: score.value + "%" }}
              >
                <span>
                  {months[new Date(score.month).getMonth()]} - {score.value} -{" "}
                  {score.users} users
                </span>
              </div>
            ))}
            <div
              className={`${style.current} ${
                style[SUScores[SUScores.length - 1].adjective]
              }`}
            >
              <small>{SUScores[SUScores.length - 1].adjective}</small>
              {SUScores[SUScores.length - 1].value}
            </div>
          </>
        ) : (
          false
        )}
      </div>
      <Modal animate={showModal} onClose={closeNewSUS} actions="hidden">
        <div>
          <div className={style.months}>
            {SUS.map((data, index) => (
              <div key="index" className={style.month}>
                {months[index]} {data.year}
                <div className={`${style.SUS} ${style[data.adjective]}`}>
                  <div className={style.adjective}>{data.adjective}</div>
                  {data.score}
                  <div className={style.numberOfUsers}>
                    {" "}
                    <span className="material-symbols-rounded">group</span>{" "}
                    {data.users}
                  </div>
                </div>
                <Button
                  name="Save"
                  onClick={() =>
                    saveSUS(
                      data.datePrint,
                      data.score,
                      data.adjective,
                      data.users
                    )
                  }
                />
              </div>
            ))}
          </div>

          <label htmlFor="csvInput" className={style.customFileUpload}>
            <span className="material-symbols-rounded">cloud_upload</span>{" "}
            {filename}
          </label>
          <input
            onChange={handleFileChange}
            id="csvInput"
            name="file"
            type="File"
            className={style.csvInput}
          />
          <div className={style.susActionContainer}>
            <button onClick={handleParse}>Calculate SUS</button>
          </div>
          {/* <div style={{ marginTop: "12px" }}>
            {error ? error : data.map((col, index) => <div key={index}>{col}</div>)}
          </div> */}
        </div>
      </Modal>
    </>
  );
}

export default SUS;
