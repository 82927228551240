import { useEffect, useRef, createElement, useState } from "react";
import style from "./ImageCell.module.css";

function ImageCell(props) {
  const canvasRef = useRef();
  const [imageSelected, setImageSelected] = useState(false);

  useEffect(() => {
    const context = canvasRef.current.getContext("2d");
    if (props.data.imageName) {
      const image = new Image();
      // image.src = props.data.content;
      image.src = "https://www.cxshed.com/static/journey_images/" + props.data.imageName;
      image.onload = () => {
        context.drawImage(image, 0, 0, 220, 390);
      };
    } else {
      context.font = "14px Arial";
      context.fillStyle = "#999";
      let textString = "360 x 640"
      let textWidth = context.measureText(textString ).width;
      context.fillText(textString, (canvasRef.current.width/2) - (textWidth / 2), 170);

      textString = "Select panel,"
      textWidth = context.measureText(textString ).width;
      context.fillText(textString, (canvasRef.current.width/2) - (textWidth / 2), 190);

      textString = "then copy and paste image"
      textWidth = context.measureText(textString ).width;
      context.fillText(textString, (canvasRef.current.width/2) - (textWidth / 2), 210);
    }
  }, [props.data]);

  useEffect(() => {
    document.addEventListener("mousedown", function (event) {
      if (canvasRef.current && !canvasRef.current.contains(event.target)) {
        props.unSelect();
      }
    });
  }, []);

  function clicked() {
    props.onClick();
  }

  return (
    <canvas
      ref={canvasRef}
      width="220"
      height="390"
      className={`${props.selected ? style.selected : ""} ${style.canvas}`}
      onClick={clicked}
    ></canvas>
    // <div
    //   className={`${props.selected ? style.selected : ""} ${style.canvas}`}
    //   ref={canvasRef}
    //   onClick={clicked}
    // >
    //   <img
    //     src={
    //       "https://www.cxshed.com/static/journey_images/" + props.data.imageName
    //     }
    //   />
    // </div>
  );
}

export default ImageCell;
