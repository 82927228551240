import { useEffect, useState, useContext } from "react";
import { ref, update, onValue, remove, push, connectDatabaseEmulator } from "firebase/database";

import UserContext from "../../user/User";

import db from "../../../firebase/Firebase";

import RadioTabs from "../../molecules/formElements/radioButtons/RadioTabs";
import ChipSelector from "../../molecules/formElements/select/ChipSelector";
import Select from "../../molecules/formElements/select/Select";
import ContentEditable from "react-contenteditable";

import Slider from "../../molecules/sliders/Slider";
import Modal from "../../organisms/modals/Modal";
import style from "./InitiativeDetails.module.css";
import ColorPicker from "../../molecules/formElements/colorPicker/ColorPicker";
import Checkbox from "../../molecules/formElements/checkboxes/Checkbox";
import Button from "../../molecules/buttons/Button";
import Nuggets from "../../organisms/nuggets/Nuggets";
import Loading from "../../molecules/loading/Loading";

function InitiativeDetails(props) {
  const user = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);

  const [initiativeID, setInitiativeID] = useState(null);
  const [initiativeRef, setInitiativeRef] = useState(null);

  const [title, setTitle] = useState("");
  const [color, setColor] = useState("rgb(218, 233, 233)");
  const [needsReviewing, setNeedsReviewing] = useState(false);
  const [reach, setReach] = useState(0);
  const [impact, setImpact] = useState(0.5);
  const [confidence, setConfidence] = useState(0);
  const [effort, setEffort] = useState(0.5);
  const [complexity, setComplexity] = useState(0.5);
  const [num, setNum] = useState(0);
  const [description, setDescription] = useState("");
  const [nuggets, setNuggets] = useState([]);
  const [squads, setSquads] = useState();

  const [squadNew, setSquadNew] = useState();
  const [statusNew, setStatusNew] = useState("Active");
  const [strateficFitNew, setStrateficFitNew] = useState(0);
  const [strateficItemsNew, setStrateficItemsNew] = useState('');

  const [nuggetFlag, setNuggetFlag] = useState(true);

  const [possibleStrategicItems, setPossibleStrategicItems] = useState();
  const [status, setStatus] = useState([
    { key: 1, name: "Active", value: "Active" },
    { key: 2, name: "On Hold", value: "onHold" },
    { key: 3, name: "Completed", value: "Complete" },
  ]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (user) {
      let possibleSquadsTemp = [];
      if (user.possibleSquads) {
        for (let squad in user.possibleSquads) {
          possibleSquadsTemp.push({
            key: user.possibleSquads[squad].key,
            name: user.possibleSquads[squad].name,
            value: user.possibleSquads[squad].key,
          });
        }
      }
      setSquads(possibleSquadsTemp);
    }

    if (user.role === "view" || !user.role || user.role === undefined) {
      setIsAdmin(false);
      setIsSuperUser(false);
    }
    if (user.role === "superUser") {
      setIsAdmin(true);
      setIsSuperUser(true);
    }
    if (user.role === "admin") {
      setIsAdmin(true);
      setIsSuperUser(false);
    }
  }, [user]);

  useEffect(() => {
    if (user.possibleSquads) {
      for (let squadInst in user.possibleSquads) {
        setPossibleStrategicItems(
          user.possibleSquads[squadInst].settings[0].values
        );
      }
    }
    if (user.squad) {
      setSquadNew(user.squad);
    }
  }, [user]);

  useEffect(() => {
    if (props.details) {
      if (props.details === "new") {
        setInitiativeID(props.details);
        setInitiativeRef(false);
      } else {
        setShowLoading(true);
        setInitiativeID(props.details.id);
        setInitiativeRef(ref(db, "initiatives/" + props.details.id));
      }

      if (props.num) {
        setNum(props.num);
      }

      if (props.isNew) {
        setTitle("");
        setColor("rgb(218, 233, 233)");
        setNeedsReviewing(false);
        setReach(0);
        setImpact(0.5);
        setConfidence(0);
        setEffort(0.5);
        setComplexity(0.5);
        setNum(0);
        setDescription("");
        setNuggets([]);

        setStatusNew("Active");
        setStrateficFitNew(0);
        setStrateficItemsNew("");
        
        let possibleOptionsTemp = [];
        let defaultListClone = { ...possibleStrategicItems };

        for (const key in defaultListClone) {
          defaultListClone[key].checked = false;
          defaultListClone[key].value = defaultListClone[key].key;
          possibleOptionsTemp.push({
            ...defaultListClone[key],
          });
        }
        setPossibleStrategicItems(possibleOptionsTemp);
      }
    }
  }, [props.details.id]);

  useEffect(() => {
    if (props.isNew) {
      setShowLoading(false);
      let squadsTemp = [];
      squads.forEach((squad) => {
        squad.selected = false;
        if (squad.value === squadNew) {
          squad.selected = true;
        }
        squadsTemp.push(squad);
      });
      setSquads(squadsTemp);

      let statusTemp = [];
      status.forEach((stat) => {
        stat.selected = false;
        if (stat.value === statusNew) {
          stat.selected = true;
        }
        statusTemp.push(stat);
      });
      setStatus(statusTemp);
    }
    if (initiativeRef && !props.isNew) {
      return onValue(initiativeRef, (snapshot) => {
        const data = snapshot.val();
        setDescription(data.description);
        setTitle(data.name);
        setColor(data.color);
        setReach(data.reach);
        setImpact(data.impact);
        setConfidence(data.confidence);
        if (data.nuggets) {
          setNuggets(data.nuggets);
        } else {
          setNuggets([]);
        }
        setEffort(data.effort);
        setComplexity(data.complexity);
        if (data.needsReviewing) {
          setNeedsReviewing(data.needsReviewing);
        } else {
          setNeedsReviewing(false);
        }

        let squadsTemp = [];
        squads.forEach((squad) => {
          squad.selected = false;
          if (squad.value === data.squad) {
            squad.selected = true;
          }
          squadsTemp.push(squad);
        });
        setSquads(squadsTemp);

        let statusTemp = [];
        status.forEach((stat) => {
          stat.selected = false;
          if (stat.value === data.status) {
            stat.selected = true;
          }
          statusTemp.push(stat);
        });
        setStatus(statusTemp);

        let itemArr = data.strategicItems.split(",");
        let possibleOptionsTemp = [];
        let defaultListClone = { ...possibleStrategicItems };

        for (const key in defaultListClone) {
          let checked = false;
          if (itemArr.includes(defaultListClone[key].key)) {
            checked = true;
          }
          defaultListClone[key].checked = checked;
          defaultListClone[key].value = defaultListClone[key].key;
          possibleOptionsTemp.push({
            ...defaultListClone[key],
          });
        }
        setPossibleStrategicItems(possibleOptionsTemp);
        setShowLoading(false);
      });
    }
  }, [initiativeID, squadNew, props.isNew]);

  function deleteItem() {
    if (initiativeRef) {
      if (window.confirm("Are you sure you want to delete this initiative?")) {
        remove(initiativeRef);
        closeHandler();
      }
    }
  }

  let scoringScale = [
    { key: 1, name: "0.5", value: 0.5 },
    { key: 2, name: "1", value: 1 },
    { key: 3, name: "2", value: 2 },
    { key: 4, name: "3", value: 3 },
    { key: 5, name: "5", value: 5 },
  ];

  function titleChangeHandler(e) {
    if (!props.isNew) {
      update(initiativeRef, {
        name: e.target.value,
      });
    } else {
      setTitle(e.target.value);
    }
  }
  function descriptionChangeHandler(e) {
    if (!props.isNew) {
      update(initiativeRef, {
        description: e.target.value,
      });
    } else {
      setDescription(e.target.value);
    }
  }
  function squadsChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        squad: val,
      });
    } else {
      setSquadNew(val);
    }
  }
  function statusChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        status: val,
      });
    } else {
      setStatusNew(val);
    }
  }
  function colorChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        color: val,
      });
    } else {
      setColor(val);
    }
  }
  function reachChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        reach: val,
      });
    } else {
      setReach(val);
    }
  }
  function impactChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        impact: val,
      });
    } else {
      setImpact(val);
    }
  }
  function confidenceChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        confidence: val,
      });
    } else {
      setConfidence(val);
    }
  }
  function effortChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        effort: val,
      });
    } else {
      setEffort(val);
    }
  }
  function complexityChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        complexity: val,
      });
    } else {
      setComplexity(val);
    }
  }
  function strategicFitChangeHandler(val) {
    if (!props.isNew) {
      update(initiativeRef, {
        strategicFit: val.length,
        strategicItems: val.join(","),
      });
    } else {
      setStrateficFitNew(val.length);
      setStrateficItemsNew(val.join(","));
    }
  }

  function needsReviewChangeHandler(e) {
    if (!props.isNew) {
      update(initiativeRef, {
        needsReviewing: e.target.checked,
      });
    } else {
      setNeedsReviewing(e.target.checked);
    }
  }

  function removeNugget(ref) {
    let tempNuggets = nuggets;
    var i = tempNuggets.length;
    setNuggetFlag(!nuggetFlag);
    while (i--) {
      if (tempNuggets[i] === ref) {
        tempNuggets.splice(tempNuggets.indexOf(ref), 1);
      }
    }
    if (!props.isNew) {
      update(initiativeRef, {
        nuggets: tempNuggets,
      });
    } else {
      setNuggets(tempNuggets);
    }
  }
  function addNugget(ref) {
    let tempNuggets = nuggets;
    setNuggetFlag(!nuggetFlag);
    tempNuggets.push(ref);
    if (!props.isNew) {
      update(initiativeRef, {
        nuggets: tempNuggets,
      });
    } else {
      setNuggets(tempNuggets);
    }
  }

  function saveName() {
    let newData = {
      color: color,
      name: title,
      squad: squadNew,
      status: statusNew,
      needsReviewing: needsReviewing,
      description: description,
      reach: reach,
      impact: impact,
      confidence: confidence,
      effort: effort,
      complexity: complexity,
      strategicFit: strateficFitNew,
      strategicItems: strateficItemsNew,
      nuggets: nuggets,
    };
    if (!title || !squadNew || !statusNew) {
      alert(
        "Please ensure you have:\r\n - an Initiative Title\r\n - a Squad\r\n - and a Status\r\n to create a new initiative"
      );
      return false;
    } else {
      // console.log(newData);
      let newInitiativeRef = ref(db, "initiatives/");
      push(newInitiativeRef, newData).then((data) => {
        newData.id = data.key;
        props.onClose(newData);
        alert(
          "Your initiative had been saved.  Changes will auto save from now on."
        );
      });
    }
  }


  function closeHandler() {
    console.log('closing')
    // setInitiativeRef(false);
    // initiativeRef.off();
    
    props.onClose();
  }

  if (!props.details || !possibleStrategicItems) {
    return false;
  }



  return (
    <Modal
      animate={props.animate}
      onClose={closeHandler}
      variant={["wrapperOnly", "large"]}
    >
      <Loading show={showLoading} />
      {!isAdmin && !isSuperUser ? <div className={style.overlay}></div> : false}
      <div className={style.initiativeDetails}>
        <main>
          <div className={style.title}>
            <ColorPicker color={color} onChange={colorChangeHandler}>
              {num}
            </ColorPicker>
            <ContentEditable
              html={title}
              className={style.titleInner}
              onChange={titleChangeHandler}
              placeholder={"Initiative Title"}
            />
          </div>
          <div className={style.row}>
            <div>
              <h2>Settings</h2>
              <div className={style.row}>
                <Select
                  label="Squad"
                  values={squads}
                  onChange={squadsChangeHandler}
                />
                <Select
                  label="Status"
                  values={status}
                  onChange={statusChangeHandler}
                />
                <Checkbox
                  label="Needs Reviewing"
                  defaultValue="needsReviewing"
                  checked={needsReviewing}
                  variant="notContained"
                  onChange={needsReviewChangeHandler}
                />
              </div>
            </div>
          </div>

          <div className={style.descriptionContainer}>
            <h2>Problem Statement</h2>
            <ContentEditable
              html={description}
              className={style.description}
              onChange={descriptionChangeHandler}
            />
          </div>
          <div>
            <h2>Nuggets</h2>
            <Nuggets
              nuggetFlag={nuggetFlag}
              nuggets={nuggets}
              removeNugget={removeNugget}
              addNugget={addNugget}
            />
          </div>
          {props.isNew ? (
            <div className={style.actions}>
              <Button onClick={closeHandler} name="Close" />
              <Button onClick={saveName} name="Save New" />
            </div>
          ) : (
            <div className={style.actions}>
              <Button onClick={deleteItem} name="Delete" variant="red" />
              <Button onClick={closeHandler} name="Close" />
            </div>
          )}
        </main>
        <aside>
          <div className={style.asideInner}>
            <div>
              <h2>Reach</h2>
              <div className={style.helper}>
                The percentage of users affected by this initiative across the
                whole customer base
              </div>
              <Slider
                min="0"
                max="100"
                value={reach}
                onChange={reachChangeHandler}
              />
            </div>
            <div>
              <h2>Impact</h2>
              <div className={style.helper}>
                How much impact will this initiative have on the audience you
                selected in Reach?
              </div>
              <RadioTabs
                selected={impact}
                tabGroup="impact"
                tabs={scoringScale}
                onChange={impactChangeHandler}
              />
            </div>
            <div>
              <h2>Confidence</h2>
              <div className={style.helper}>
                How much confidence do you have in these values?
              </div>
              <Slider
                min="0"
                max="100"
                value={confidence}
                onChange={confidenceChangeHandler}
              />
            </div>
            <div>
              <h2>Effort</h2>
              <div className={style.helper}>
                How many person months will it take to complete this work?
              </div>
              <RadioTabs
                selected={effort}
                tabGroup="effort"
                tabs={scoringScale}
                onChange={effortChangeHandler}
              />
            </div>
            <div>
              <h2>Complexity</h2>
              <div className={style.helper}>
                How many dependencies are related to this initiative?
              </div>
              <RadioTabs
                selected={complexity}
                tabGroup="complexity"
                tabs={scoringScale}
                onChange={complexityChangeHandler}
              />
            </div>
            <div>
              <h2>Strategic Fit</h2>
              <ChipSelector
                possibleOptions={possibleStrategicItems}
                onChange={strategicFitChangeHandler}
              />
            </div>
          </div>
        </aside>
      </div>
    </Modal>
  );
}

export default InitiativeDetails;
