import List from "../../organisms/list/List";
import { ref, onValue, update } from "firebase/database";
import db from "../../../firebase/Firebase";
import style from "./AreaMapping.module.css";
import { useEffect, useState } from "react";
import Button from "../../molecules/buttons/Button";
import AreaMapDetails from "./AreaMapDetails";

function AreaMapping() {
  const areaMapsRef = ref(db, "areaMaps");
  const [listData, setListData] = useState({ headings: [], rows: [] });
  const [animate, setAnimate] = useState(false);
  const [selectedAreaMap, setSelectedAreaMap] = useState(false);

  let tempListData = {
    headings: [{ value: "Area Map Name", left: true }, { value: "" }],
    rows: [],
  };

  function openHandler(id) {
    setSelectedAreaMap(id);
    setAnimate(!animate);
  }
  function closeModalHandler() {
    setAnimate(!animate);
  }

  useEffect(() => {
    onValue(areaMapsRef, (snapshot) => {
      snapshot.forEach((row) => {
        tempListData.rows.push({
          id: row.key,
          columns: [
            { value: row.val().name, left: true, bold: true },
            { value: <Button name="View" /> },
          ],
        });
      });
      setListData(tempListData);
    });
  }, []);

  return (
    <>
      <List listData={listData} itemClick={openHandler} />
      <AreaMapDetails
        details={selectedAreaMap}
        animate={animate}
        onClose={closeModalHandler}
      />
    </>
  );
}

export default AreaMapping;
