import style from "./Tabs.module.css";

const Tabs = (props) => {
  return (
    <ul className={style.tabs}>
      {props.items.map((tab, index) => (
        <li
          key={index}
          className={tab.selected ? style.selected : null}
          onClick={() => tab.onClick(tab, index)}
        >
          {tab.name}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
