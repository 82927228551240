import Button from "../../molecules/buttons/Button";
import style from "./Card.module.css";

function Card(props) {
  return (
    <div className={style.card}>
      <div>
        <img
          src={props.image}
          alt=""
        />
        <div className={style.cardContent}>
          <h2>{props.title}</h2>
          <div className={style.description}>{props.description}</div>
        </div>
      </div>
      <div className={style.actions}>
        <Button name="Go" />
      </div>
    </div>
  );
}

export default Card;
