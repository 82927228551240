import { useEffect, useState, useContext } from "react";
import { ref, onValue, update } from "firebase/database";
import { useNavigate } from "react-router-dom";

import UserContext from "../../user/User";

import style from "./Discovery.module.css";
import db from "../../../firebase/Firebase";
import Button from "../../molecules/buttons/Button";
import Fab from "../../molecules/buttons/Fab";
import InitiativeDetails from "./InitiativeDetails";
import NewInitiative from "./NewInitiative";
import Select from "../../molecules/formElements/select/Select";
import Checkbox from "../../molecules/formElements/checkboxes/Checkbox";
import Year from "./Year";

function Discovery() {
  const user = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isSuperUser, setIsSuperUser] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const planningRef = ref(db, "initiatives");
  const [rawInitiatives, setRawInitiatives] = useState(false);
  const [loadedInitiatives, setLoadedInitiatives] = useState([]);

  const [usersSquad, setUsersSquad] = useState(user.squad);
  const userRef = ref(db, "users/" + user.id);

  const [hideCompleted, setHideCompleted] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [animateNew, setAnimateNew] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState(false);
  const [selectedInitiativeNumber, setSelectedInitiativeNumber] = useState(0);
  const [squads, setSquads] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.id) {
        navigate("/");
      }
      onValue(planningRef, (snapshot) => {
        setRawInitiatives(snapshot.val());
      });
      setUsersSquad(user.squad);
      let possibleSquadsTemp = [];
      if (user.possibleSquads) {
        for (let squad in user.possibleSquads) {
          let selected = '';

          if(user.squad === user.possibleSquads[squad].key) {
            selected = 'selected'
          }

          possibleSquadsTemp.push({
            key: user.possibleSquads[squad].key,
            name: user.possibleSquads[squad].name,
            value: user.possibleSquads[squad].key,
            selected: selected,
          });
        }
      }
      setSquads(possibleSquadsTemp);
    }

    if(user.role === 'view' || !user.role || user.role === undefined){
      setIsAdmin(false);
      // setIsSuperUser(false);
    }
    if(user.role === 'superUser'){
      setIsAdmin(true);
      // setIsSuperUser(true);
    }
    if(user.role === 'admin'){
      setIsAdmin(true);
      // setIsSuperUser(false);
    }
  },[user]);

  useEffect(() => {
    if (rawInitiatives && user && usersSquad) {
      let data = rawInitiatives;
      const initiatives = [];
      for (const key in data) {
        if (usersSquad === data[key].squad && data[key].type !== "update") {
          const reach = parseInt(data[key].reach) + 1;
          const impact = parseFloat(data[key].impact);
          const confidence = parseInt(data[key].confidence) + 1;
          const effort = parseFloat(data[key].effort);
          const complexity = parseFloat(data[key].complexity);
          const strategicFit = parseInt(data[key].strategicFit) + 1;
          const score = Math.round(
            ((reach * impact * confidence) / (effort * complexity)) *
              strategicFit
          );

          let squadName = "-";
          for (let squad in squads) {
            if (squads[squad].key === data[key].squad) {
              squadName = squads[squad].name;
            }
          }
          const initiative = {
            id: key,
            squadName: squadName,
            score: score,
            ...data[key],
          };
          if (hideCompleted && data[key].status === "Complete") {
            //do nothing
          } else {
            initiatives.push(initiative);
          }
        }
      }
      initiatives.sort((a, b) => {
        if (a["score"] < b["score"]) {
          return 1;
        }
        if (a["score"] > b["score"]) {
          return -1;
        }
        return 0;
      });

      let lowestScore = 0;
      let range = 0;
      if (initiatives[initiatives.length - 1]) {
        lowestScore = initiatives[initiatives.length - 1].score;
        range = initiatives[0].score - lowestScore;
      }
      let i = 0;
      for (const key in initiatives) {
        i++;
        initiatives[key].num = i;

        initiatives[key].percentage =
          1 -
          (parseInt(initiatives[key].score) - parseInt(lowestScore)) / range;
      }
      setLoadedInitiatives(initiatives);
      setIsLoading(false);
    }
  }, [usersSquad, rawInitiatives, hideCompleted, user, squads]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getColor(value) {
    //value from 0 to 1
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",65%,70%)"].join("");
  }

  function openDetailsHandler(initiative) {
    // console.log(initiative);
    setIsNew(false);
    setSelectedInitiativeNumber(initiative.num);
    setSelectedInitiative(initiative);
    setAnimate(true);
  }

  function closeModalHandler(initiative) {
    if (initiative) {
      if (initiative.id) {
        openDetailsHandler(initiative);
      }else{
        setAnimate(false);
      }
    }else{
      setAnimate(false);
    }
  }

  function closeNewModalHandler(initiative) {
    setAnimateNew(false);
    if (initiative.id) {
      openDetailsHandler(initiative);
    }
  }

  function createNew() {
    setSelectedInitiativeNumber('0');
    setSelectedInitiative("new");
    setIsNew(true);
    setAnimate(true);
  }

  function selectedSquadsChangeHandler(val) {
    if (val) {
      setUsersSquad(val);
      update(userRef, {
        squad: val,
      });
    }
  }

  function hideCompletedChangeHandler(e) {
    setHideCompleted(e.target.checked);
  }

  if (isLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      
      <Year />
      <table className={style.list} cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>#</th>
            <th>Initiative Name</th>
            <th>
              <Checkbox
                defaultValue="hideCompleted"
                checked={hideCompleted}
                onChange={hideCompletedChangeHandler}
                label="Hide Completed"
                variant="notContained"
              />
            </th>
            <th>
              <Select
                variant="transparent"
                name="squads"
                label="Squad"
                values={squads}
                onChange={selectedSquadsChangeHandler}
                placeholder=" "
              />
            </th>
            <th>Score</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {loadedInitiatives.map((initiative) => (
            <tr
              key={initiative.id}
              className={`${style[initiative.status]}`}
              onClick={() => openDetailsHandler(initiative)}
            >
              <td className={style.num}>
                <span style={{ borderColor: initiative.color }}>
                  {initiative.num}
                </span>
              </td>
              <td>{initiative.name}</td>
              <td>
                {initiative.needsReviewing ? (
                  <span className={style.needsReviewing}>Needs Reviewing</span>
                ) : (
                  ""
                )}
              </td>
              <td>{initiative.squadName}</td>
              <td
                className={style.score}
                style={{ backgroundColor: getColor(initiative.percentage) }}
              >
                {numberWithCommas(initiative.score)}
              </td>
              <td>
                <Button name="View" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loadedInitiatives.length ? (
        ""
      ) : (
        <div className={style.noItems}>
          There are no initiatives for this squad
        </div>
      )}
      {isAdmin ? (
        <Fab name="Create New" icon="add" onClick={createNew} />
      ) : (
        ""
      )}
      {/* <NewInitiative animate={animateNew} onClose={closeNewModalHandler} /> */}
      <InitiativeDetails
        isNew={isNew}
        num={selectedInitiativeNumber}
        details={selectedInitiative}
        animate={animate}
        onClose={closeModalHandler}
      />
    </div>
  );
}

export default Discovery;
