import { useEffect, useState } from "react";
import Button from "../../molecules/buttons/Button";
import style from "./Modal.module.css";

function Modal(props) {
  const [styles, setStyles] = useState("");
  let actions = null;
  if (props.actions !== "hidden") {
    actions = (
      <div className={style.modalActions}>
        {props.actions ? props.actions.map((i, index) => (<Button key={index} name={i.name} onClick={i.onClick} />)) : <Button name="Done" />}
      </div>
    );
  }

  useEffect(() => {
    let tempStyles = "";
    if (props.variant) {
      props.variant.forEach((variantInstance) => {
        tempStyles += " " + style[variantInstance];
      });
      setStyles(tempStyles);
    }
  }, [props, style]);

  return (
    <div
      className={`${style.modal} ${styles} ${props.animate && style.modalShow}`}
    >
      <div className={style.modalBackground} onClick={props.onClose}></div>
      <div className={style.modalContents}>
        <div className={style.modalContentsInner}>
          <div className={style.modalTitle}>{props.title}</div>
          <div className={style.modalContentsInnerContents}>
            {props.children}
          </div>
        </div>
        {actions}
      </div>
    </div>
  );
}

export default Modal;
