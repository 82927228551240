import { useEffect, useState, useRef } from "react";
import Checkbox from "../checkboxes/Checkbox";
import style from "./ChipSelector.module.css";

function ChipSelector(props) {
  const [possibleOptions, setPossibleOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [animate, setAnimate] = useState(0);
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAnimate(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    let options = [];
    let selOpts = [];
    for (const key in props.possibleOptions) {
      if (props.possibleOptions[key].checked) {
        selOpts.push(props.possibleOptions[key]);
      }else{
        props.possibleOptions[key].checked = false;
      }
      options.push(props.possibleOptions[key]);
    }
    setSelectedOptions(selOpts);
    setPossibleOptions(options);
  }, [props]);

  function removeHandler(remove) {
    let newArray = [];
    let selOpts = [];
    possibleOptions.map((item) => {
      let newItem = item;
      if (parseInt(item.value) === parseInt(remove.value)) {
        newItem.checked = false;
        newItem.key = Date() + "-" + item.value;
        selOpts.push(item);
      }
      newArray.push(newItem);
    });
    setSelectedOptions(selOpts);
    setPossibleOptions(newArray);
    let toReturn=[];
    newArray.map((i) => {
      if(i.checked) {
        toReturn.push(i.value);
      }
    });
    props.onChange(toReturn);
  }

  function changeHandler(e) {
    let newArray = [];
    let selOpts = [];
    possibleOptions.map((item) => {
      let newItem = item;
      if (item.key === e.target.value) {
        newItem.checked = e.target.checked;
        selOpts.push(item);
      }
      newArray.push(newItem);
    });
    setSelectedOptions(selOpts);
    setPossibleOptions(newArray);
    let toReturn=[];
    newArray.map((i) => {
      if(i.checked) {
        toReturn.push(i.key);
      }
    });
    props.onChange(toReturn);
  }

  function showOptionsHandler() {
    setAnimate(!animate);
  }

  if (props.possibleOptions) {
    return (
      <div className={style.chipsContainer}>
        <button onClick={showOptionsHandler}>Add</button>
        {selectedOptions.length ? (
          <div className={style.chips}>
            {possibleOptions.map((item, index) =>
              item.checked ? (
                <div key={item.key} className={style.chip}>
                  {item.name}{" "}
                  <span
                    className="material-symbols-rounded"
                    onClick={() => removeHandler(item)}
                  >
                    close
                  </span>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          <div className={style.noItems}>No Items Selected</div>
        )}
        <div ref={ref} className={`${style.options} ${animate && style.show}`}>
          {possibleOptions.map((item) => (
            <div key={item.key}>
              <Checkbox
                key={item.key}
                defaultValue={item.key}
                onChange={changeHandler}
                label={item.name}
                checked={item.checked}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ChipSelector;
