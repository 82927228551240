import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAWQqDX7eKSMCXbSJGJK6-11oSnUQg8zeI",
  authDomain: "planning-ce620.firebaseapp.com",
  databaseURL:
    "https://planning-ce620-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "planning-ce620",
  storageBucket: "planning-ce620.appspot.com",
  messagingSenderId: "492468805148",
  appId: "1:492468805148:web:289fcfb98d380c0e6749d9",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export default db;