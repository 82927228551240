import { useState, useEffect, useRef } from "react";
import { ref, update, onValue } from "firebase/database";
import { Stage, Layer, Rect, Circle, Image as ImageK } from "react-konva";

import db from "../../../firebase/Firebase";

import Modal from "../../organisms/modals/Modal";
import style from "./AreaMapDetails.module.css";

function AreaMapDetails(props) {
  const [areaMapID, setAreaMapID] = useState(false);
  const [areaMapRef, setAreaMapRef] = useState(false);
  const [details, setDetails] = useState(false);
  const [imageParent, setImageParent] = useState(false);
  const [canvasOffset, setCanvasOffset] = useState([0,0]);
  const imageRef = useRef();

  const [realisation, setRealisation] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [optimisation, setOptimisation] = useState(0);


  useEffect(() => {
    if (props.details) {
      setAreaMapID(props.details);
      setAreaMapRef(ref(db, "areaMaps/" + props.details));
    }
  }, [props]);

  useEffect(() => {
    if (areaMapRef) {
      onValue(areaMapRef, (snapshot) => {
        let data = snapshot.val();
        let arr = [];
        let areas = [];
        areas["realisation"] = 0;
        areas["communication"] = 0;
        areas["optimisation"] = 0;

        for (const item of Object.keys(data.areas)) {
          let area = data.areas[item];
          arr.push(area);
          areas[area.name] += area.width * area.height;
        }
        // let imageArea = imageRef.current.width * imageRef.current.height;
        let imageArea = 100000;
        setRealisation(Math.round((areas["realisation"] / imageArea) * 100));
        setCommunication(
          Math.round((areas["communication"] / imageArea) * 100)
        );
        setOptimisation(Math.round((areas["optimisation"] / imageArea) * 100));

        data.areas = arr;
        setDetails(data);
        const image = new Image();
        // image.onload = function () {
        // }
        image.src = data.interfaceImage;
        let x = (400 - data.width/2);
        let y = (350 - data.height/2);
        setCanvasOffset([x, y]);
        setImageParent(<ImageK image={image} width={data.width} height={data.height} shadowBlur="8" shadowOpacity=".3" x={x} y={y} />)

      });
    }
  }, [areaMapID]);

  return (
    <Modal
      animate={props.animate}
      onClose={props.onClose}
      variant={["wrapperOnly", "large"]}
    >
      <div className={style.areaMap}>
        <div className={style.results}>
          <div className={`${style.realisation} ${style.solid}`}>
            {realisation}% Realisation
          </div>
          <div className={`${style.communication} ${style.solid}`}>
            {communication}% Communication
          </div>
          <div className={`${style.optimisation} ${style.solid}`}>
            {optimisation}% Optimisation
          </div>
        </div>
        <div className={style.image}>
          <Stage width={800} height={700}>
            <Layer>
              {imageParent}
            </Layer>
            <Layer x={canvasOffset[0]} y={canvasOffset[1]}>
            {details.areas
              ? details.areas.map((area, index) => (
                <Rect key={index} x={area.x} y={area.y} width={area.width} height={area.height} fill={area.fill} draggable={true} />
                ))
              : false}
            </Layer>
          </Stage>
        </div>
      </div>
    </Modal>
  );
}

export default AreaMapDetails;
