import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ref, onValue } from "firebase/database";

import db from "./firebase/Firebase";

import Home from "./components/pages/home/Home";
import Planning from "./components/pages/planning/Planning";
import Discovery from "./components/pages/discovery/Discovery";
import Layout from "./components/templates/layout/Layout";
import UserContext from "./components/user/User";
import Settings from "./components/pages/account/Settings";
import AreaMapping from "./components/pages/areaMapping/AreaMapping.js";
import Presentation from "./components/pages/presentations/Presentation";
import Presentations from "./components/pages/presentations/Presentations";
import Users from "./components/pages/account/Users";
import Journeys from "./components/pages/journeys/Journeys";
import Journey from "./components/pages/journeys/Journey";
import QuarterDetails from "./components/pages/discovery/QuarterDetails";

function App() {
  const [user, setUser] = useState({});
  const [squadDetails, setSquadDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setUserDetails({});
      if (user && user.email) {
        let email = user.email;
        let userRefID = email.replaceAll(".", "-");
        userRefID = userRefID.replace("@", "-at-");
        const userRef = ref(db, "users/" + userRefID);

        onValue(userRef, (snapshot) => {
          let tempUser = snapshot.val();
          tempUser.id = snapshot.key;
          tempUser.email = email;
          setUserDetails(tempUser);
        });
      }
    });
  }, []);

  useEffect(() => {
    setUser({
      ...userDetails,
      ...squadDetails,
    });
  }, [userDetails, squadDetails]);

  function showSettingsHandler() {
    setShowSettings(!showSettings);
  }

  function showUsersHandler() {
    setShowUsers(!showUsers);
  }

  function setProduct(products) {
    setSquadDetails({
      possibleSquads: products,
    });
  }

  return (
    <UserContext.Provider value={user}>
      <Layout showSettings={showSettingsHandler} showUsers={showUsersHandler}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/planning" element={<Planning />} />
          
          <Route path="/discovery">
            <Route index element={<Discovery />} />
            <Route path=":quarter" element={<QuarterDetails />} />
          </Route>
          <Route path="/journeys">
            <Route index element={<Journeys />} />
            <Route path=":squad/:id" element={<Journey />} />
          </Route>
          <Route path="/area-mapping" element={<AreaMapping />} />
          <Route path="/presentations">
            <Route index element={<Presentations />} />
            <Route path=":presentationID" element={<Presentation />} />
          </Route>
        </Routes>
      </Layout>
      <Settings
        userDetails={userDetails}
        animate={showSettings}
        onClose={showSettingsHandler}
        setProduct={setProduct}
      />
      <Users animate={showUsers} onClose={showUsersHandler} />
    </UserContext.Provider>
  );
}

export default App;
