import { useEffect, useRef, useState } from "react";
import Modal from "../modals/Modal";
import style from "./Nuggets.module.css";
import Nugget from "./Nugget";

function Nuggets(props) {
  const [showNuggetListModal, setShowNuggetListModal] = useState(false);
  const [nuggets, setNuggets] = useState([]);
  const libraryRef = useRef();
  const [nuggetList, setNuggetList] = useState([
    { name: "1.scarcity" },
    { name: "2.status" },
    { name: "3.social-proof" },
    { name: "4.prospect-theory" },
    { name: "5.reciprocity" },
    { name: "6.risk-aversion" },
    { name: "7.framing" },
    { name: "8.picture-superiority-effect" },
    { name: "9.analysis-paralysis" },
    { name: "10.limited-choice" },
    { name: "11.fluency-shortcut" },
    { name: "12.certainty-effect" },
    { name: "13.feedback-loops" },
    { name: "14.loss-aversion" },
    { name: "15.self-expression" },
    { name: "16.authority" },
    { name: "17.storyteller-bias" },
    { name: "18.default-effect" },
    { name: "19.priming" },
    { name: "20.ikea-effect" },
    { name: "21.anchoring" },
    { name: "22.commitment" },
    { name: "23.autonomy-bias" },
    { name: "24.zeigarnik-effect" },
    { name: "25.endowed-progress-effect" },
    { name: "26.nostalgia-effect" },
    { name: "27.rewards" },
    { name: "28.surprise-effect" },
    { name: "29.limited-access" },
    { name: "30.reactance" },
    { name: "31.confirmation-bias" },
    { name: "32.humor-effect" },
    { name: "33.rhyme-as-reason-effect" },
    { name: "34.spacing-effect" },
    { name: "35.curiosity-effect" },
    { name: "36.sunk-cost-bias" },
    { name: "37.peak-end-rule" },
    { name: "38.tiny-habits" },
    { name: "39.fast-and-slow-thinking" },
    { name: "40.metaphorical-shortcut" },
    { name: "41.chunking" },
    { name: "42.contrast-effect" },
    { name: "43.collection-bias" },
    { name: "44.goal-gradient-effect" },
    { name: "45.hedonic-adaptation" }
  ]);

  useEffect(() => {
    setNuggets(props.nuggets);
    let tempNuggetList = [];
    nuggetList.map((nugget, index) => {
      nuggetList[index].state = false;
      if (props.nuggets.includes(nugget.name)) {
        nuggetList[index].state = "added";
      }
      tempNuggetList.push(nuggetList[index]);
    });

    setNuggetList(tempNuggetList);
  }, [props.nuggets, props.nuggetFlag]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (libraryRef.current && !libraryRef.current.contains(event.target)) {
        if (event.target.className !== style.new) {
          setShowNuggetListModal(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [libraryRef]);

  function showNuggetList(e) {
    e.stopPropagation();
    setShowNuggetListModal(!showNuggetListModal);
  }
  function closeNuggetList() {
    setShowNuggetListModal(false);
  }
  function removeNugget(ref) {
    props.removeNugget(ref);
  }
  function addNugget(ref) {
    props.addNugget(ref);
  }

  return (
    <div className={style.nuggetsContainer}>
      {nuggets.length > 3 ? (
        <span className={style.careful}>
          Careful, you might be creating a Frankenstein
        </span>
      ) : (
        false
      )}
      <div className={style.nuggets}>
        {nuggets.map((nugget, index) => (
          <Nugget
            key={index}
            name={nugget}
            state="added"
            variant="inSelectedList"
            removeNugget={removeNugget}
            addNugget={addNugget}
          />
        ))}
        <div className={style.new} onClick={(e) => showNuggetList(e)}>
          {showNuggetListModal ? "-" : "+"}
        </div>
      </div>
      <div
        ref={libraryRef}
        className={`${style.nuggetLibrary} ${
          showNuggetListModal && style.show
        }`}
      >
        {nuggetList.map((nugget, index) => (
          <Nugget
            key={index}
            name={nugget.name}
            state={nugget.state}
            variant={["list"]}
            removeNugget={removeNugget}
            addNugget={addNugget}
          />
        ))}
      </div>
    </div>
  );
}

export default Nuggets;
