import Quarter from "./Quarter";
import style from "./Year.module.css"

const Year = () => {
  return <div className={style.year}>
    <span className={style.title}>2023</span>
    <div>
      <Quarter title="Q1" />
      <Quarter title="Q2" />
      <Quarter title="Q3" />
      <Quarter title="Q4" />
    </div>
  </div>
}


export default Year;