import { useEffect, useState } from 'react';
import uuid from 'react-native-uuid';

import style from './Checkbox.module.css'

function Checkbox(props) {
  const [checked, setChecked] = useState(0);
  const uniqueId = uuid.v4();

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <div className={`${style.checkbox} ${style[props.variant]}`}>
      <input type="checkbox" id={uniqueId} defaultValue={props.defaultValue} checked={checked} onChange={props.onChange}/> <label htmlFor={uniqueId} >{props.label}</label>
    </div>
  );
}

export default Checkbox;
