import { useEffect, useState, useRef } from "react";
import style from "./Select.module.css";

function Select(props) {
  const [possibleValues, setPossibleValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [val, setVal] = useState("");
  const [error, setError] = useState(null);
  const [animate, setAnimate] = useState(false);
  const ref = useRef(null);
  const { onClickOutside } = props;
  const inpRef = useRef(null);

  useEffect(() => {
    setError(props.error);
    if(props.onParentChange) {
      props.onParentChange(props.value);
    }
  }, [props]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAnimate(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if(props.values) {
      setPossibleValues(props.values);
    }
  },[props]);

  useEffect(() => {
    if(possibleValues.length) {
      possibleValues.forEach((opt) => {
        if (opt.selected) {
          setSelectedValue(opt.name);
          setVal(opt.value);
        }
      });
    }
  }, [possibleValues]);

  function optionClickHandler(opt) {
    setSelectedValue(opt.name);
    setVal(opt.value);
    props.onChange(opt.value);
  }

  function changeHandler(e) {
    setSelectedValue(e.target.value);
    let matches = false;
    props.values.forEach((opt) => {
      if (opt.name.search(e.target.value) > -1) {
        matches = true;
      }
    });
    if (matches) {
      setError(null);
    } else {
      setError(true);
    }
  }

  function showList() {
    setAnimate(!animate);
  }

  function focusHandler(event) {
    event.currentTarget.querySelector("input").focus();
  }

  return (
    <div
      className={`${style.select} ${animate && style.show} ${props.variant && style[props.variant]}`}
      onClick={showList}
      ref={ref}
    >
      <div
        className={`${style.textField} ${error && style.error}`}
        onClick={focusHandler}
      >
        <input value={val || ''} type="hidden" />
        <input
          className={style.textInput}
          ref={inpRef}
          spellCheck="false"
          name={props.name}
          type="text"
          placeholder="search"
          value={selectedValue || ''}
          onChange={changeHandler}
        />
        <label>{props.label}</label>
      </div>

      <div className={style.options}>
        {props.values.map((opt, index) => (
          <div key={index} onClick={() => optionClickHandler(opt)}>
            {opt.name}
          </div>
        ))}
        {props.cta ? <div className={style.cta} onClick={props.ctaOnClick}>{props.cta}</div> : ""}
      </div>
    </div>
  );
}

export default Select;
