import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ref,
  update,
  onValue,
  remove,
  push,
  set,
  connectDatabaseEmulator,
} from "firebase/database";
import db from "../../../firebase/Firebase";
import { toPng } from "html-to-image";

import ContextMenu from "./ContextMenu";
import style from "./Journey.module.css";
import SUS from "./SUS";
import ContentEditable from "react-contenteditable";
import ImageCell from "./cells/ImageCell";
import ConversionCell from "./cells/ConversionCell";
import BarChart from "./cells/BarChart";
import Button from "../../molecules/buttons/Button";
import Fab from "../../molecules/buttons/Fab";
import Time from "./cells/Time";
import Select from "../../molecules/formElements/select/Select";
import Modal from "../../organisms/modals/Modal";
import TextField from "../../molecules/formElements/TextField";
// import useDynamicRefs from 'use-dynamic-refs';

function Journey() {
  const params = useParams();
  const journeyElRef = useRef();
  const journeyRef = ref(
    db,
    "journeys/" + params.squad + "/items/" + params.id
  );
  
  const [contextMenuPosition, setContextMenuPosition] = useState({});
  const [contextSelectedCell, setContextSelectedCell] = useState({});
  const [contextMenuType, setContextMenuType] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [mouseXPostion, setMouseXPostion] = useState(0);
  // const [currentColumn, setCurrentColumn] = useState(0);
  const imageRef = useRef();
  const currentImageData = { data: {} };
  const [startingColspan, setStartingColspan] = useState(false);
  const [journeyData, setJourneyData] = useState();
  const [editDisabled, setEditDisabled] = useState(true);
  const [currentVersion, setCurrentVersion] = useState();
  const currentVersionAlt = {};
  const [versions, setVersions] = useState([]);
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [newVersion, setNewVersion] = useState("");

  useEffect(() => {
    if (params.id) {
      return onValue(journeyRef, (snapshot) => {
        // console.log(snapshot.val());
        const currentDate = new Date();
        let data = snapshot.val();
        let newRows = [];
        let lastItem = "";
        // console.log('finding version')
        if (!currentVersion) {
          // console.log('getting last item')
          lastItem = Object.keys(data.version);
          lastItem = lastItem[lastItem.length - 1];
          setCurrentVersion(lastItem);
          currentVersionAlt.version = lastItem;
          // console.log('last item', lastItem)
        } else {
          // console.log('alt')
          lastItem = currentVersion;
        }

        setVersions([]);
        for (let v in data.version) {
          let selected = false;
          if (v === lastItem) {
            selected = true;
          }
          setVersions((current) => [
            ...current,
            { name: data.version[v].name, value: v, selected: selected },
          ]);
        }
        console.log(lastItem)
        console.log(data)
        data.version[lastItem].rows.map((row, index1) => {
          if (row.type === "conversion") {
            row.time = currentDate.getTime();
          }
          if (row.type === "barChart") {
            let values = [];
            row.columns.map((column, index) => {
              values.push(parseFloat(column.content));
            });
            data.version[lastItem].rows[index1].max = Math.max(...values);
            row.time = currentDate.getTime();
          }
          row.originalIndex = index1;
          newRows[row.displayOrder - 1] = row;

          // if (row.type === "image") {
          //   row.columns.map((column, index) => {
          //     if (!column.imageName) {
          //       const reader = new FileReader();

          //       var imgObj = dataURItoBlob(column["content"]);

          //       reader.onload = function () {
          //         saveImageData(
          //           reader.result,
          //           params,
          //           { row: index1, col: index },
          //           currentVersionAlt
          //         );
          //       };

          //       reader.readAsDataURL(imgObj);
          //     }
          //   });
          // }
        });
        data.newRows = newRows;
        setJourneyData(data);
      });
    }

    // function dataURItoBlob(dataURI) {
    //   var byteString = atob(dataURI.split(",")[1]);

    //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    //   var ab = new ArrayBuffer(byteString.length);

    //   var ia = new Uint8Array(ab);

    //   for (var i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //   }

    //   var blob = new Blob([ab], { type: mimeString });
    //   return blob;
    // }


  }, []);




  useEffect(() => {
    const reader = new FileReader();
    
    const pasteWrapper = (e) => {
      pasteHandler(e);
    };
    
    window.addEventListener("paste", pasteWrapper);

    function pasteHandler(e) {
      if (e.clipboardData == false) return false;
      var imgs = e.clipboardData.items;
      if (imgs == undefined) return false;
      for (var i = 0; i < imgs.length; i++) {
        if (imgs[i].type.indexOf("image") == -1) continue;
        var imgObj = imgs[i].getAsFile();

        reader.onload = function () {
          saveImageData(reader.result, params, imageRef, currentVersionAlt);
        };

        reader.readAsDataURL(imgObj);
      }
    }

    return () => {
      reader.onload = null;
      window.removeEventListener("paste", pasteWrapper);
    };
  },[])



  
  function saveImageData(src, paramst, imageReft, currentVersionAltt) {
    // console.log("================");
    // console.log('saving image');
    // console.log("params.squad", params.squad);
    // console.log("params.id", params.id);
    // console.log("currentVersionAlt.version", currentVersionAlt.version);
    // console.log("imageRef.row", imageRef.row);
    // console.log("imageRef.col", imageRef.col);
    // console.log("content", src.length);
    // console.log("================");

    // update(
    //   ref(
    //     db,
    //     "journeys/" +
    //       params.squad +
    //       "/items/" +
    //       params.id +
    //       "/version/" +
    //       currentVersionAlt.version +
    //       "/rows/" +
    //       imageRef.row +
    //       "/columns/" +
    //       imageRef.col
    //   ),
    //   { content: src }
    // );

    fetch("/php/saveImage.php", {
      method: "POST",
      body: JSON.stringify({ image: src }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (imageRef) {
          update(
            ref(
              db,
              "journeys/" +
                paramst.squad +
                "/items/" +
                paramst.id +
                "/version/" +
                currentVersionAltt.version +
                "/rows/" +
                imageReft.row +
                "/columns/" +
                imageReft.col
            ),
            { imageName: data.name }
          );
        }
      });
  }

  function handleShowContextMenu(e, rowData, columnIndex, rowIndex) {
    if (!editDisabled) {
      e.preventDefault();
      setContextMenuType(rowData.type);
      setContextSelectedCell({
        rowData: rowData,
        columnIndex: columnIndex,
        rowIndex: rowIndex,
      });
      setContextMenuPosition({ top: e.pageY + "px", left: e.pageX + "px" });
      setShowContextMenu(true);
    }
  }
  function closeContextMenu() {
    setShowContextMenu(false);
  }
  function contextMenuAction(action, data) {
    switch (action) {
      case "cellRight":
        data.rowData.columns.splice(
          data.columnIndex + 1,
          0,
          data.rowData.columns[data.columnIndex]
        );
        break;

      case "cellLeft":
        data.rowData.columns.splice(
          data.columnIndex - 1,
          0,
          data.rowData.columns[data.columnIndex]
        );
        break;

      case "cellDelete":
        if (data.columnIndex) {
          data.rowData.columns.splice(data.columnIndex, 1);
        }else{
          alert('Journeys must contain at least one column');
          return false;
        }
        break;

      case "columnRight":
        journeyData.version[currentVersion].rows.map((row) => {
          if (row.columns[data.columnIndex]) {
            row.columns.splice(
              data.columnIndex + 1,
              0,
              row.columns[data.columnIndex]
            );
          } else {
            row.columns[row.columns.length - 1].colspan =
              parseInt(row.columns[row.columns.length - 1].colspan) + 1;
          }
        });
        break;

      case "columnLeft":
        journeyData.version[currentVersion].rows.map((row) => {
          row.columns.splice(
            data.columnIndex - 1,
            0,
            row.columns[data.columnIndex]
          );
        });
        break;

      case "columnDelete":
        if (data.columnIndex) {
          journeyData.version[currentVersion].rows.map((row) => {
            row.columns.splice(data.columnIndex, 1);
          });
        }else{
          alert('Journeys must contain at least one column');
          return false;
        }
        break;

      default:
        let actions = action.split(":");
        journeyData.version[currentVersion].rows[data.rowIndex].columns[
          data.columnIndex
        ][actions[0]] = actions[1];
        journeyData.version[currentVersion].rows[data.rowIndex].columns[
          data.columnIndex
        ]["content"] = actions[1];
    }
    delete journeyData.newRows;
    // console.log("updating 1");
    set(journeyRef, journeyData);
  }

  function handleContentChange(e, cdata, isNumber = false) {
    cdata.content = e.target.value;
    // console.log("updating 2");
    set(journeyRef, journeyData);
  }
  function handleContentChange2(e, cdata, isNumber = false) {
    cdata.content2 = e.target.value;
    // console.log("updating 3");
    set(journeyRef, journeyData);
  }

  function handleTitleChange(e, data) {
    data.name = e.target.value;
    // console.log("updating 4");
    set(journeyRef, journeyData);
  }

  function handleDescriptionChange(e, data) {
    data.description = e.target.value;
    // console.log("updating 5");
    set(journeyRef, journeyData);
  }

  function mouseDown(e, data) {
    setMouseXPostion(e.pageX);
    setStartingColspan(data.colspan);
  }
  function mouseUp() {
    setStartingColspan(false);
  }
  function mouseMove(e, data) {
    if (startingColspan && e.pageX) {
      let difference = e.pageX - mouseXPostion;
      data.colspan = startingColspan + Math.round(difference / 240);
      set(journeyRef, journeyData);
    }
  }

  function selectImage(e, data, row, col) {
    // console.log('here', row);
    if (!editDisabled) {
      data.selected = !data.selected;
      if (data.selected) {
        imageRef.row = row;
        imageRef.col = col;
        currentImageData.data = data;
      }
      let tempJourneyData = { ...journeyData };
      setJourneyData(tempJourneyData);
    }
  }

  function unSelect(e, data, row, col) {
    if (journeyData.version[currentVersion].rows[row].columns[col].selected) {
      data.selected = false;
      // journeyData.version[currentVersion].rows[row].columns[
      //   col
      // ].selected = false;
      // let tempJourneyData = { ...journeyData };
      // setJourneyData(tempJourneyData);
      // console.log("journeyData", journeyData);
      // console.log('updating 7');
      // set(journeyRef, journeyData);
    }
  }

  if (!journeyData) {
    return false;
  }

  function filter(node) {
    return node.tagName !== "BUTTON";
  }

  function exportToSVG() {
    toPng(journeyElRef.current, { filter: filter }).then(function (dataUrl) {
      const link = document.createElement("a");
      link.download = "journey.png";
      link.href = dataUrl;
      link.click();
    });
  }

  function editMode() {
    setEditDisabled(!editDisabled);
  }

  function newVersionClick() {
    if (editDisabled) {
      alert("You must be in edit more to create a new version");
    } else {
      setShowVersionModal(true);
    }
  }

  function closeNew() {
    setShowVersionModal(false);
    // journeyRef = false;
  }
  function saveNew() {
    if (newVersion.length > 2) {
      let tempJourneyData = { ...journeyData };
      push(
        ref(
          db,
          "journeys/" + params.squad + "/items/" + params.id + "/version"
        ),
        { name: newVersion, rows: tempJourneyData.version[currentVersion].rows }
      );
      setShowVersionModal(false);
    } else {
      alert("Version name must be more than 3 characters");
    }
  }
  function versionHandler(val) {
    setNewVersion(val.target.value);
  }

  function changeState(e, cdata, isNumber = false) {
    cdata.state = e.target.checked;
    set(journeyRef, journeyData);
  }

  function changeVersion() {
    alert("coming soon");
  }

  return (
    <div className={style.journey} ref={journeyElRef}>
      <div className={style.journeyHeader}>
        <div className={style.left}>
          <Link to="/journeys/">
            <span className="material-symbols-rounded">arrow_back</span>
          </Link>
          <div className={style.titleContainer}>
            <h1>
              <ContentEditable
                html={"" + journeyData.name}
                onChange={(e) => handleTitleChange(e, journeyData)}
                disabled={editDisabled}
              />{" "}
              -{" "}
              <Select
                variant="transparentSmall"
                name="version"
                label="Version"
                values={versions.reverse()}
                onChange={changeVersion}
                // name="November 2022"
                placeholder=" "
                cta="Create New Version"
                ctaOnClick={newVersionClick}
              />
            </h1>
            <ContentEditable
              html={"" + journeyData.description}
              onChange={(e) => handleDescriptionChange(e, journeyData)}
              disabled={editDisabled}
            />
          </div>
        </div>
        <SUS
          scores={journeyData.sus}
          id={params.id}
          squad={params.squad}
          disabled={editDisabled}
        />
      </div>
      <ContextMenu
        show={showContextMenu}
        style={contextMenuPosition}
        closeContextMenu={closeContextMenu}
        contextMenuAction={contextMenuAction}
        item={contextSelectedCell}
        type={contextMenuType}
      />
      <div className={style.rows}>
        {journeyData.newRows.map((rdata, index1) => (
          <div
            key={index1}
            className={`${style.row} ${style[rdata.type]} ${
              editDisabled && style.disabled
            }`}
          >
            <div style={{ "--colspan": 1 }}>{rdata.name}</div>
            {rdata.columns.map((cdata, index2) => (
              <div
                key={index2}
                className={`${style.outer} ${style[cdata.subType]}`}
                style={{ "--colspan": cdata.colspan }}
                onContextMenu={(e) =>
                  handleShowContextMenu(e, rdata, index2, index1)
                }
              >
                <div className={style.inner}>
                  {
                    {
                      image: (
                        <>
                          <ImageCell
                            selected={cdata.selected}
                            data={cdata}
                            row={index1}
                            col={index2}
                            onClick={(e) =>
                              selectImage(
                                e,
                                cdata,
                                journeyData.newRows[index1].originalIndex,
                                index2
                              )
                            }
                            unSelect={(e) => unSelect(e, cdata, index1, index2)}
                            disabled={editDisabled}
                          />
                        </>
                      ),
                      text: (
                        <ContentEditable
                          html={"" + cdata.content}
                          onChange={(e) => handleContentChange(e, cdata)}
                          disabled={editDisabled}
                        />
                      ),
                      lifeCycle: <div>{cdata.content}</div>,
                      conversion: (
                        <ConversionCell
                          data={cdata}
                          nextData={rdata.columns[index2 + 1]}
                          onChange={(e) => handleContentChange(e, cdata, true)}
                          disabled={editDisabled}
                          time={rdata.time}
                        />
                      ),
                      barChart: (
                        <BarChart
                          max={rdata.max}
                          data={cdata}
                          nextData={rdata.columns[index2 + 1]}
                          onChange={(e) => handleContentChange(e, cdata, true)}
                          disabled={editDisabled}
                          time={rdata.time}
                        />
                      ),
                      time: (
                        <Time
                          data={cdata}
                          onChange={(e) => handleContentChange(e, cdata, true)}
                          onChange2={(e) =>
                            handleContentChange2(e, cdata, true)
                          }
                          changeState={(e) => changeState(e, cdata, true)}
                          disabled={editDisabled}
                        />
                      ),
                      comments: (
                        <ContentEditable
                          html={cdata.content ? "" + cdata.content : ""}
                          onChange={(e) => handleContentChange(e, cdata)}
                          placeholder="Comment"
                          disabled={editDisabled}
                        />
                      ),
                    }[rdata.type]
                  }
                </div>
                <div
                  className={style.dragger}
                  onDragStart={(e) => mouseDown(e, cdata)}
                  onDrag={(e) => mouseMove(e, cdata)}
                  onDragEnd={mouseUp}
                  draggable={true}
                >
                  <span className="material-symbols-rounded">
                    drag_indicator
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={style.fabContainer}>
        <Fab
          icon={editDisabled ? "edit" : "close"}
          onClick={editMode}
          name=""
          inContainer="inContainer"
        />
        {editDisabled ? (
          <Fab
            icon="download"
            onClick={exportToSVG}
            name=""
            inContainer="inContainer"
          />
        ) : (
          false
        )}
      </div>

      <Modal
        animate={showVersionModal}
        actions={[
          { name: "Close", onClick: closeNew },
          { name: "Save", onClick: saveNew },
        ]}
        onClose={closeNew}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <TextField
            label="New version name"
            value={newVersion}
            placeholder="month and year (e.g. Apr 2022)"
            onChange={versionHandler}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Journey;
