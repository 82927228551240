import { useEffect, useState } from "react";
import style from "./Slider.module.css";


function Slider(props) {
  const [val, setVal] = useState(0);

  useEffect(() => {
    setVal(props.value);
  }, [props]);


  function changeHandler(e) {
    setVal(e.target.value);
    props.onChange(e.target.value);
  }
  
  return (
    <div className={style.slider}>
      <input type="range" min={props.min} max={props.max} value={val} onChange={changeHandler} />
      <input type="text" value={val} onChange={changeHandler} />
    </div>
  );
}

export default Slider;
